<template>
  <section id="toolZone" v-click-outside="() => { set_idxClick(null) }">

    <transition name="slide-fade" mode="out-in" class="tab">
      <slot></slot>
    </transition>
    
    <div id="toolbar" :class="{opened : indexClick != null}">
      <div v-for="(img, idx) in images" :key="img" class="elem" @click="set_idxClick(idx)"
        :class="{ 'selected': (indexClick == idx), [classList[idx]] : (classList[idx] != null && classList[idx] != null)}" v-tippy='{placement: "left", content : $t(`toolbar.${names[idx]}`)}'>

      <img :src="require('src/assets' + img)" alt="">
      </div>
    </div>
  </section>


</template>

<script>

export default {
  name: "Toolbar",
  props: ["images", "modelValue", "classList", "names"], //'video_format', 'automatic_framing_change', 'watermark', 'video_editing_rhythm', 'captions', 'more'
  emits: ["update:modelValue"],
  data() {
    return {
    };
  },
  computed: {
    indexClick: {
      get() { return this.modelValue },
      set(value) {
        this.$emit("update:modelValue", value)
      }
    }
  },
  methods: {
    set_idxClick(index) {
      if (index == null && this.indexClick == 4){
          return;
      } else if(index == 4 && this.indexClick == 4){
        this.indexClick = null
      }

      if (index == this.indexClick) {
        this.indexClick = null
      } else {
        this.indexClick = index
      }
    }
  },
  watch : {
    indexClick(val){
      if (val != null){
        this.$store.state.creativeSpace.toolBarOpen = true
      } else {
        this.$store.state.creativeSpace.toolBarOpen = false
      }
    }
  }
};
</script>
    
<style src="./style.scss" lang="scss">
</style>
