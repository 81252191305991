<template>
  <div class="row" @mouseover="(mouseOver = true)" @mouseout="(mouseOver = false)" :class="{ selected}" >
    <div class="row_content" >
      <img :src="require('src/assets'+srcImg)" alt="">
      <p :style="{color}">{{ text }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "ImgTextRow",
  props: { srcImg: {}, text: {}, selected: {},color: {}},
  data() {
    return {
      mouseOver: false
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
    
<style scoped lang="scss">

img {
  margin-left: 5%;
  margin-right: 5%;
  width: 25px;
  height: 25px;
}

p {
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.row {
  width: 100%;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: fit-content;
  cursor: pointer;
  transition: background-color 0.2s;

  &:not(:first-of-type) {
    margin-top:6px;
  }

  &:hover{
    cursor: pointer;
    background-color: rgba(var(--color-toolbar), 0.2);
  }

  &.selected {
    background-color: rgba(var(--color-toolbar), 0.5) !important;
  }
}

.row.delete:hover{
    cursor: pointer;
    background-color: rgba(173, 48, 42, 0.2);
}

.row_content {
  display: flex;
  width: 100%;
  align-items: center;
}

</style>
