<template>
    
      <ToolbarInternal v-if="project_options != undefined && project_options != null" v-model="indexToolbar" @click.stop="" :images="[
      '/toolbar/' + project_options.format + '.svg',
      '/toolbar/editing_mode.png',
      project_options.watermark != null ? '/toolbar/watermark-' + project_options.watermark + '.svg' : '/toolbar/watermark-BL.svg',
      '/toolbar/captions.svg', '/toolbar/translate.png', '/more.svg']" :classList="[null, null, null, null, null, 'more']"
      :names="['video_format', 'video_editing_rhythm', 'watermark', 'captions', 'language', 'more']">
    

      <!-- TAB 0 - FORMAT -->
      
      <div v-if="(indexToolbar == 0)">
        <h2 class="toolbar_tab_header">{{ $t('ProjectOptions.format.title') }}</h2>
        <imgTextRow srcImg="/toolbar/horizontal.svg" text="Horizontal (16:9)" :selected="project_options.format == 'horizontal'" @click="toolbar_format('horizontal'); complete_onboarding_task('video_format')" />
        <imgTextRow srcImg="/toolbar/vertical.svg" text="Vertical (9:16)" :selected="project_options.format == 'vertical'" @click="toolbar_format('vertical'); complete_onboarding_task('video_format')" />
        <imgTextRow srcImg="/toolbar/square.svg" :text="$t('ProjectOptions.format.square') + ' (1:1)'" :selected="project_options.format == 'square'" @click="toolbar_format('square'); complete_onboarding_task('video_format')"/>
      </div>
      
      <!-- TAB 1 - EDITING -->

      <div v-if="(indexToolbar == 1)">
        <h2 class="toolbar_tab_header">{{ $t('ProjectOptions.editing_mode.title') }}</h2>
        <div>
          <p style="font-weight: 500; margin-bottom:10px;">{{ $t('ProjectOptions.editing_mode.templates') }}</p>
          <SelectDropdown :options="editingModesTemplate" :placeholder="$t('ProjectOptions.editing_mode.select_template')" @select="(event) => putProjectData({ 'project_options': { 'editing_mode': event.value } })" />
        </div>
        <div class="inputRange">
          <p>{{ $t('ProjectOptions.cut_level.title') }} : <a id="cut_level_value_displayed" style="font-weight: 600;">{{ $t('ProjectOptions.cut_level.' + project_options.cut_level)
          }}</a></p>
          <input id="cut_level" v-model="cut_level_valueInt" type="range" min="0" max="3" step="1" @change="complete_onboarding_task('remove_silence'); putProjectData({'project_options': { 'cut_level': project_options.cut_level }})" />
        </div>

        <div style="display: flex;justify-content: space-between;margin-top:20px;" >
          <p>{{ $t('ProjectOptions.auto_crop.title') }} <img src='@/assets/info.svg' class="tooltip" @mouseover="playTippyVideo('tippyVideo_crop')" v-tippy="{ maxWidth: 500, allowHTML: true, content: tippyContent.projectOptionCrop, placement: 'left'}"/></p>
          <Toggle v-model="project_options.auto_crop"
            @update:modelValue="putProjectData({ 'project_options': { 'auto_crop': project_options.auto_crop } })"
            id="toggle_framing" />

        </div>
        <div style="display: flex;justify-content: space-between;margin-top:20px;">
          <p>Zoom <img src='@/assets/info.svg' class="tooltip" @mouseover="playTippyVideo('tippyVideo_zoom')" v-tippy="{ maxWidth: 500, allowHTML: true, content: tippyContent.projectOptionZoom, placement: 'left'}"/> </p>
          <Toggle v-model="project_options.zoom"
            @update:modelValue="putProjectData({ 'project_options': { 'zoom': project_options.zoom } })"
            id="toggle_zoom" />

        </div>

        <div style="display: flex;justify-content: space-between;margin-top:20px;">
          <p>{{ $t('ProjectOptions.audio_enhancement') }} </p>
          <Toggle v-model="project_options.audio_enhanced" id="toggle_audio"
            @update:modelValue="putProjectData({ 'project_options': { 'audio_enhanced': project_options.audio_enhanced } }); complete_onboarding_task('sound_enhancement')" />

        </div>
      </div>
    
      <!-- TAB 2 - WATERMARK -->
      
      <div v-if="(indexToolbar == 2)">
        <div class="toolbar_tab_header">
          <h2>Watermark </h2>
          <Toggle v-model="watermark_bool" :disabled="!isSubscribed" id="toggle" />
        </div>
        <p v-if="!isSubscribed"
          style="text-align: center;font-weight: bold;margin-bottom: 8%;margin-top: 5%;color: var(--color_negative);">
          {{ $t('ProjectOptions.watermark.notEligible') }}</p>
        <EditableImage :editable="false" image_type="watermark" src_default="default_watermark"
          :src_img="identity.ident_watermark" @click="openIdentity"
          style="display:flex;text-align: center;max-height: 10vh;" />
        <h4 v-if="project_options.watermark != null" style="margin-top: 16px;">
          {{ $t('ProjectOptions.watermark.logoLocation') }}</h4>


        <selectImg v-if="project_options.watermark != null" style="margin: auto;margin-bottom: 10%;margin-top: 5%;"
          v-model="project_options.watermark" :data="[
            { srcImg: '/toolbar/watermark-BL.svg', value: 'BL' },
            { srcImg: '/toolbar/watermark-BR.svg', value: 'BR' },
            { srcImg: '/toolbar/watermark-TL.svg', value: 'TL' },
            { srcImg: '/toolbar/watermark-TR.svg', value: 'TR' }]"
          @update:modelValue="indexToolbar = null; putProjectData({ 'project_options': project_options })" />



        <h4>{{ $t('ProjectOptions.description') }}</h4>
        <p style="margin-bottom: 10px">{{ $t('ProjectOptions.watermark.description') }}
        </p>
        <h4>{{ $t('ProjectOptions.overview') }}</h4>
        <video src="https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/Watermark_video_sample.mp4"
          muted="muted" autoplay loop></video>
      </div>


      <!-- TAB 4 - CAPTIONS -->
      
      <div v-if="(indexToolbar == 3)">
        <div class="toolbar_tab_header">
          <h2>Captions </h2>
          <Toggle v-model="project_options.captions_settings.enable" id="toggle"
            @update:modelValue="updateCaptionsSettings()" />
        </div>

        <div>
          <p style="font-weight: 500;">{{ $t('ProjectOptions.captions.templates') }}</p>
          <SelectDropdown :options="captionsTemplates" :placeholder="$t('ProjectOptions.captions.select_template')" @select="loadCaptionTemplate" />
        </div>
        <div id="wordByPage" class="inputRange">
          <p>{{$t('ProjectOptions.captions.word_by_page')}} : <a style="font-weight: 600;">{{ project_options.captions_settings.wordByPage }}</a></p>
          <input v-model="project_options.captions_settings.wordByPage" type="range" min="1" max="20" step="1"
            @change="updateCaptionsSettings()" />
        </div>
        <div class="captionsColors">
          <div class="captionsColorsLine">
            <div class="captionsColor">
              <a>{{$t('ProjectOptions.captions.background_color')}}</a>
              <ColorPicker @click.stop="stopPropagation" class="colorPicker"
                v-model="project_options.captions_settings.backgroundColor" :swatches="getCurrentUser.colorSwatches"
                :enableAlpha="true" :enableInput="true" defaultValue="#00ADB5"
                @update:modelValue="updateCaptionsSettings()" />
            </div>
            <div class="captionsColor">
              <a>{{$t('ProjectOptions.captions.font_color')}}</a>
              <ColorPicker @click.stop="stopPropagation" class="colorPicker"
                v-model="project_options.captions_settings.fontColor" :swatches="getCurrentUser.colorSwatches"
                :enableAlpha="true" :enableInput="true" defaultValue="#00ADB5"
                @update:modelValue="updateCaptionsSettings()" />
            </div>
          </div>
          <div class="captionsColorsLine">
            <div class="captionsColor">
              <a>{{$t('ProjectOptions.captions.focus_color')}}</a>
              <ColorPicker @click.stop="stopPropagation" class="colorPicker"
                v-model="project_options.captions_settings.focusColor" :swatches="getCurrentUser.colorSwatches"
                :enableAlpha="true" :enableInput="true" defaultValue="#00ADB5"
                @update:modelValue="updateCaptionsSettings()" />
            </div>
            <div class="captionsColor">
              <a>{{$t('ProjectOptions.captions.border_color')}}</a>
              <ColorPicker @click.stop="stopPropagation" class="colorPicker"
                v-model="project_options.captions_settings.borderColor" :swatches="getCurrentUser.colorSwatches"
                :enableAlpha="true" :enableInput="true" defaultValue="#00ADB5"
                @update:modelValue="updateCaptionsSettings()" />
            </div>
          </div>
        </div>
        <div class="captionStrokeAndSize">
          <div>
            <p>{{$t('ProjectOptions.captions.font_size')}}</p>
            <v-select :options="[6, 8, 10, 12, 14, 18, 20, 24, 30, 35, 40, 45, 50, 55, 60]"
              v-model="project_options.captions_settings.fontSize" @update:modelValue="updateCaptionsSettings()" />
          </div>
          <div>
            <p>{{$t('ProjectOptions.captions.border_size')}}</p>
            <v-select :options="[0, 1, 2, 3, 4, 5]" v-model="project_options.captions_settings.borderSize"
              @update:modelValue="updateCaptionsSettings()" />
          </div>
        </div>
        <div class="fontName">
          <p>{{$t('ProjectOptions.captions.font_name')}}</p>
          <v-select
            :options="picassoFonts"
            v-model="project_options.captions_settings.fontName" @update:modelValue="updateCaptionsSettings()" />
        </div>
        <div class="wordAnimation">
          <p>{{$t('ProjectOptions.captions.word_animation')}}</p>
          <v-select :options="['Page', 'wordByWord', 'slideFromBottom']"
            v-model="project_options.captions_settings.wordAnimation" @update:modelValue="updateCaptionsSettings()" />
        </div>
        <div class="fontPosition">
          <p>{{$t('ProjectOptions.captions.position')}} : <a style="font-weight: 600;">{{ project_options.captions_settings.position }}%</a></p>
          <input v-model="project_options.captions_settings.position" type="range" min="0" max="100" step="1"
            @change="updateCaptionsSettings()" />
        </div>
      </div>

      <!-- TAB 4 - Language -->

      <div v-if="(indexToolbar == 4)">
        <div class="toolbar_tab_header">
          <h2>{{$t('Languages.language')}} <a style="color:var(--color1)">(Beta)</a></h2>
        </div>

        <div>
          <SelectDropdown :options="languages_choices" :placeholder="languageDropdownPlaceholder" @select="changeProjectLanguage"/>
        </div>
      </div>

      <!-- TAB 5 - MORE -->

      <div v-if="(indexToolbar == 5)">
        <imgTextRow srcImg="/toolbar/duplicate.png" :text="$t('Duplicate')" @click.stop="toolbar_action('duplicate')" />
        <imgTextRow srcImg="/toolbar/saveTemplate.png" :text="$t('CreativeSpace.saveAsTemplate')"
          @click.stop="toolbar_action('saveTemplate')" />
        <imgTextRow srcImg="/toolbar/trash_red.png" :text="$t('Delete')" class="delete" color="var(--color_negative)"
          @click.stop="toolbar_action('delete')" />
      </div>


    </ToolbarInternal>


</template>




<script>
import axios from "axios"

//STORE
import Vuex from "vuex";
import store from "src/store/index";

import ToolbarInternal from "src/components/Object/Toolbar/Toolbar.vue";
import imgTextRow from "src/components/Object/Toolbar/components/imgTextRow.vue";
import selectImg from "src/components/Object/Toolbar/components/selectImg.vue"
import SelectDropdown from "src/components/Object/SelectDropdown/SelectDropdown.vue";
import EditableImage from "src/components/Object/EditableImage.vue";


import captions_templates from "../../captionsTemplates"

import Picasso from "@yopbox/picasso"
const picassoFonts = Picasso.getAvailableFonts();


const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
    name: "Toolbar",
    components : {
        ToolbarInternal,
        SelectDropdown,
        imgTextRow,
        selectImg,
        EditableImage
    },
    store: { store },
    data: function() {
        return {
            project_id : this.$route.params.project_id,
            indexToolbar : null,
            cutLevelValues: ["none", "low", "medium", "high"],
            picassoFonts : picassoFonts,
            editingModesTemplate: [
                {
                    label: this.$t('ProjectOptions.editing_mode.original'),
                    img: "/video_media_blue.png",
                    value: "original"
                },
                {
                    label: this.$t('ProjectOptions.editing_mode.natural'),
                    img: "/toolbar/editing_mode_natural.png",
                    value: "natural"
                },
                {
                    label: this.$t('ProjectOptions.editing_mode.dynamic'),
                    img: "/toolbar/editing_mode_dynamic.png",
                    value: "dynamic"
                },
                {
                    label: this.$t('ProjectOptions.editing_mode.intense'),
                    img: "/toolbar/editing_mode_intense.png",
                    value: "intense"
                }
            ],
            captionsTemplates: [
                {
                    label: "Mr Beast",
                    img: "/captionsTemplates/mr_beast.png",
                    value: "mr_beast"
                },
                {
                    label: "Minimal",
                    img: "/captionsTemplates/minimal.png",
                    value: "minimal"
                },
                {
                    label: "Dynamic",
                    img: "/captionsTemplates/dynamic.png",
                    value: "dynamic"
                }
            ],
            tippyContent : {
              projectOptionCrop : "<p style='padding:5px'> <video id='tippyVideo_crop' src='https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/Autocrop_video_sample.mp4' webkit-playsinline='true' playsinline='true' loop autoplay muted width='380px' ></video></p>",
              projectOptionZoom : "<p style='padding:5px'> <video id='tippyVideo_zoom' src='https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/tippy_zoom_3.mp4' webkit-playsinline='true' playsinline='true' loop autoplay muted width='380px' ></video></p>"
            },
            languages_choices : [
              {
                value : null,
                label : this.$t('Languages.original'),
                img : null
              },
              {
                value : 'en',
                label : this.$t('Languages.en_US'),
                img : null
              },
              {
                value : 'fr',
                label : this.$t('Languages.fr_FR'),
                img : null
              },
              {
                value : 'it',
                label : this.$t('Languages.it_IT'),
                img : null
              },
              {
                value : 'es',
                label : this.$t('Languages.es_ES'),
                img : null
              },
              {
                value : 'pt',
                label : this.$t('Languages.pt_PT'),
                img : null
              },
              {
                value : 'ru',
                label : this.$t('Languages.ru_RU'),
                img : null
              },
              {
                value : 'ar',
                label : this.$t('Languages.ar_SA'),
                img : null
              },
              {
                value : 'hi',
                label : this.$t('Languages.hi_IN'),
                img : null
              },
              {
                value : 'zh',
                label : this.$t('Languages.zh_CN'),
                img : null
              },
            ]
        }
    },
    watch : {
      indexToolbar(value){
        if (value == 3){
          this.complete_onboarding_task('dynamic_caption');
        }
      }
    },
    computed: {
      ...Vuex.mapGetters([
        "identity",
        "isSubscribed",
        "getCurrentUser"
      ]),
      project_options() {
        return this.$store.state.creativeSpace.project_data.project_options
      },
      cut_level_valueInt: {
        get() {
            const index = this.cutLevelValues.findIndex(v => v == this.project_options.cut_level)
            if (index != -1) {
              return index
            } else {
              return null
            }

        },
        set(newValue) {
          this.project_options.cut_level = this.cutLevelValues[newValue]
        }
      },
      project_timeline(){
        return this.$store.state.creativeSpace.project_data.project_timeline
      },
      watermark_bool: {
        get() {
          if (this.project_options?.watermark == undefined || this.project_options?.watermark == null
          ) {
            return false
          } else {
            return true
          }
        },
        set(value) {
          if (!value) {
            this.project_options.watermark = null
          } else {
            this.project_options.watermark = 'BL'
          }
          this.putProjectData({ 'project_options': this.project_options })
        }
      },
      project_language(){
        return this.$store.state.creativeSpace.project_data.project_language
      },
      languageDropdownPlaceholder(){
        const language = this.languages_choices.find(l => l.value == this.project_language);
        if (language != undefined){
          return language.label
        } else {
          return this.$t('toolbar.chooseLanguage')
        }
      }
    },
    methods: {
      ...Vuex.mapActions([
          "putProjectData",
          "deleteProject",
          "duplicateProject",
          "complete_onboarding_task"
      ]),
      ...Vuex.mapMutations(['SHOW_CATALOG']),
      stopPropagation(e) {
        e.stopPropagation();
      },
      toolbar_editing_mode(mode) {
          //COMPLETE ONBOARDING TASK
          this.complete_onboarding_task('change_editing_mode')

          this.indexToolbar = null

          this.putProjectData({ 'project_options': { ...this.project_options, editing_mode: mode } })
      },
      toolbar_format(format) {
        this.indexToolbar = null
        this.project_options.format = format
        this.putProjectData({ 'project_options': this.project_options })

        this.project_timeline.forEach((part, index) => {  // refresh all animation custom of project with new identity selected
          if (part.anim_custom_id != null) {
            if (this.$store.state.creativeSpace.part_expanded == index) {
              this.$store.commit('SET_EXPANDED_ANIM', undefined)
            }
            let text = part.anim_type == 1 ? part.title_content : undefined
            axios.put('/animations/' + part.anim_custom_id, {
              ident_id: this.identity._id,
              text,
              format: format
            })
              .then((res) => {
                if (this.$store.state.creativeSpace.part_expanded == index) {
                  this.$store.commit('SET_EXPANDED_ANIM', res.data)
                }
              })
          }
        });
      },
      toolbar_action(type) {
        this.indexToolbar = null
        switch (type) {
          case "delete":
            this.Toaster.prompt({
              title: this.$t('Toaster.areYouSure'),
              message: this.$t('Home.prompt.will_delete_project'),
              validate: this.$t('Delete'),
              cancel: this.$t('Cancel')
            }).then((response) => {
              if (response == "validate") {
                this.deleteProject(this.project_id)
                  .then(() => {
                    this.$router.push({ path: '/' })
                  })
              }
            })
            break;

          case "saveTemplate":
            axios.post('/templates', { project_id: this.project_id })
              .then(() => {
                this.Toaster.success(this.$t("Toaster.templates.template_saved_successfully"))
              })
              .catch(() => {
                this.Toaster.error(this.$t("Toaster.templates.template_saved_failed"))
              })
            break;

          case "duplicate":
            this.duplicateProject(this.project_id)
              .then((duplicate) => {
                this.Toaster.success(this.$t("Home.success.duplication"));
                let routeData = this.$router.resolve({ path: '/edit/' + duplicate._id });
                window.open(routeData.href, '_blank');
              })
              .catch(() => {
                this.Toaster.error(this.$t("Home.error.duplication"));
              });
            break;
        }
      },
      updateCaptionsSettings() {
        this.putProjectData({ 'project_options': { "captions_settings": this.project_options.captions_settings } })
      },
      loadCaptionTemplate(evt) {
          this.project_options.captions_settings = { enable: true, ...captions_templates[evt.value] };
          this.updateCaptionsSettings()
      },
      async playTippyVideo(videoId){
          while (document.getElementById(videoId) == null) {
              await sleep(100)
          }
          document.getElementById(videoId).currentTime = 0;
          document.getElementById(videoId).play()
      },

      openIdentity() {
        if (this.isSubscribed) {
          if (this.identity._id != undefined) {
            this.$parent.identityMode = 'update'
          }
          this.$store.commit('COLLAPSE_ALL_CLIPS')

          this.$nextTick(() => {
            this.SHOW_CATALOG(true)

          })
        }
      },

      changeProjectLanguage(evt){
        this.complete_onboarding_task('change_caption_language')
        //this.putProjectData({"project_language" : evt.value})
        this.$store.dispatch('changeProjectLanguage', evt.value)
        this.indexToolbar = null
      }
    },
}
</script>


<style lang="scss" src="./toolBar.scss" scoped></style>