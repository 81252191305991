<template>
  <div class="panel" panel-position="0">
    <!-- Title -->
    <p class="navigation_title">
      <a @click="cancel">{{ $t("CreativeSpace.title") }}</a>
      <a> / </a>
      <a> {{ $t("CreativeSpace.chooseMood") }} </a>
    </p>

    <!-- List of mood -->
    <div class="cardList center">
      <div
        v-for="mood in moods"
        v-bind:key="mood._id"
        class="card"
        @mouseenter="musicPlayed.over = mood._id"
      >
        <div class="visual_container">
          <div
            class="playOverlay"
            v-if="
              mood.mood_name != 'Pas de musique' &&
              musicPlayed.over != mood._id &&
              musicPlayed.moodId != mood._id
            "
          >
            <span>
              <img src="../../../assets/play.svg" />
            </span>
          </div>
          <img
            :src="
              mood.mood_thumbnail && mood.mood_thumbnail.media_url
                ? mood.mood_thumbnail.media_url
                : ''
            "
          />
        </div>
        <div class="card-bottom">
          <div>
            <p class="card-title">
              {{ displayName(mood.mood_name, mood.mood_nameTraduct) }}
            </p>
            <p class="template" v-if="template_mood_id != undefined && template_mood_id == mood._id">Template</p>

            <img
              v-if="idSelected != null && idSelected == mood._id"
              src="../../../assets/checked_white.png"
              class="item_check"
            />
            <p v-else class="button" @click="select(mood._id)">
              {{ formatPrice(mood.mood_price) }}
            </p>
          </div>
        </div>

        <div
          class="render_processing_message"
          v-if="
            (mood.mood_name != 'Pas de musique' &&
              musicPlayed.over == mood._id) ||
            musicPlayed.moodId == mood._id
          "
        >
          <p>
            {{ $t("MoodChoice.extract") }}<br />{{
              displayName(mood.mood_name, mood.mood_nameTraduct)
            }}
          </p>
          <div
            style="
              display: inline-flex;
              justify-content: space-around;
              width: 25%;
              margin-top: 5%;
              cursor: pointer;
            "
          >
            <span
              v-if="musicPlayed.loading == mood._id"
              class="loading_spinner center"
            ></span>
            <img
              v-if="
                musicPlayed.loading != mood._id &&
                musicPlayed.paused != mood._id
              "
              @click="playMusic(mood._id)"
              src="../../../assets/play.svg"
              style="width: 15px"
            />
            <img
              v-if="
                musicPlayed.loading != mood._id &&
                musicPlayed.paused == mood._id
              "
              @click="pauseMusic(mood._id)"
              src="../../../assets/pause.svg"
              style="width: 18px"
            />
            <img
              v-if="
                musicPlayed.loading != mood._id &&
                musicPlayed.moodId == mood._id
              "
              @click="playMusic(mood._id, true)"
              src="../../../assets/shuffle.svg"
              style="width: 20px"
            />
          </div>
        </div>
      </div>
    </div>
    <audio
      hidden
      :src="musicPlayed.srcAudio"
      autoplay
      controls
      ref="audio"
    ></audio>
  </div>
</template>

<script>
// API
import collect_api from "../../../api/collect";

//NPM

import emitter from "tiny-emitter/instance";
import axios from "axios";

//STORE
import Vuex from "vuex";
import store from "../../../store/index";

export default {
  name: "MoodChoice",
  props: ["project_id", "selected_moodId", "template_mood_id"],
  store: store,

  data: function () {
    return {
      musicPlayed: {
        over: null,
        moodId: null,
        srcAudio: null,
        loading: null,
        paused: null,
      },
      lastMoodIdPlayed : null,
      moods: [],
      idSelected: null,
    };
  },
  mounted: function () {
    if (this.selected_moodId != null) {
      this.idSelected = this.selected_moodId;
    }
    setTimeout(function () {
      emitter.emit("click_location", "panel1");
    }, 500);
    collect_api
      .get("/moods/", { media: true })
      .then((res) => {
        var no_mood_id = "";
        this.moods = res
          .flatMap((mood) => {
            if (mood.mood_name == "Pas de musique") {
              no_mood_id = mood._id;
            }
            return mood;
          })
          //MOOD EXCLUSIF POLICY
          .filter((mood) => {
            if (mood.mood_exclu == true) {
              if (
                mood.mood_usrExclu.includes(this.getCurrentUsrId) ||
                this.isAdmin == true
              ) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .sort(function (x, y) {
            //BRING NO MUSIC CARD TO FIRST POSITION
            return x._id == no_mood_id ? -1 : y._id == no_mood_id ? 1 : 0;
          });
      })
      .catch((err) => {
        this.Toaster.error(this.$t("Toaster.error.dataRecovery"));

        setTimeout(() => {
          this.$emit("finished");
          emitter.emit("click_location", "panel1");
        }, 500);
      });
  },
  methods: {
    ...Vuex.mapActions(["putProjectData"]),
    displayName(name, nameTraduct) {
      if (this.$i18n.locale == "fr" || nameTraduct == undefined) {
        return name;
      } else {
        return nameTraduct[this.$i18n.locale];
      }
    },
    formatPrice(price) {
      if (price == 0 || price == undefined) {
        return this.$t("Choose");
      } else {
        return price + " €";
      }
    },
    select(mood_id){
      var mood = this.moods.find((obj) => {
        return obj._id == mood_id;
      });
      this.putProjectData({
          mood_id: mood_id,
      })
        .then(() => {
          this.idSelected = mood_id;
          this.$nextTick(() => {
            this.$store.commit('UPDATE_MOOD_DATA', mood)
            this.$emit("finished");
          });
        })
        .catch(() => {
          this.Toaster.error(this.$t("Toaster.error.update"));
        });
    },

    cancel: function () {
      this.stopMusic();
      this.$emit("finished", {});
    },
    showMood: function () {
      //showOneMood = mood._id
    },
    playMusic(mood_id, askOtherMusic) {
      if (askOtherMusic || mood_id != this.musicPlayed.moodId) {
        this.stopMusic();
      }

      this.$nextTick(() => {
        this.musicPlayed.loading = mood_id;

        if (this.musicPlayed.moodId == null || this.musicPlayed.moodId != mood_id) {
          this.musicPlayed.moodId = mood_id;

          axios
            .get(`/musics/random`, {
              params: {
                mood_id: mood_id,
                ...(this.lastMoodIdPlayed != null ? { "exclude_id" : this.lastMoodIdPlayed } : {})
              },
              responseType: "blob",
              headers: {
                "Cache-Control": "no-cache",
              },
            })
            .then((res) => {
              this.lastMoodIdPlayed = res.headers.music_id || null

              //if (this.musicPlayed.over == mood_id) {
                URL.revokeObjectURL(this.musicPlayed.srcAudio);
                this.musicPlayed.srcAudio = URL.createObjectURL(res.data);
                this.musicPlayed.loading = null;
              //}
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          this.$refs.audio.play();

          this.musicPlayed.loading = null;
        }
      });
      this.musicPlayed.paused = mood_id;
    },
    stopMusic() {
      URL.revokeObjectURL(this.musicPlayed.srcAudio);
      this.musicPlayed.moodId = null;
      this.musicPlayed.over = null;
      this.musicPlayed.loading = null;
      this.musicPlayed.paused = null;

      this.$nextTick(() => {
        this.musicPlayed.srcAudio = null;
      });
    },
    pauseMusic() {
      this.$refs.audio.pause();
      this.musicPlayed.paused = null;
    },
  },
  computed: {
    ...Vuex.mapGetters(["getVideos", "getCurrentUsrId", "isAdmin"])
  },
};
</script>

<style src="./style.css" scoped></style>