<template>
    <div id="playerPicasso"
        :style="`--background_color : ${background_color}; ${picasso?.fullscreen == true ? playerPicassoFullScreenStyle : ''}`"
        :class="'PlayerPicasso ' + project_options.format" :status="picassoIsInitialized == true ? 'ready' : 'loading'">

        <!-- OPTIMIZATION -->
        <OptimizationProgression v-if="optimization_progression != null" :progression="optimization_progression || null"
            :style="`--background_color : ${background_color}`" />

        <!-- LOADING -->
        <div v-if='(!picassoIsInitialized || !picassoIsReady) && optimization_progression == null'
            class="loading">
            <span class="loading_spinner"></span>
            <a>{{ $t('loading') }} ...</a>
        </div>

        <!-- CONTROLS -->
        <Controls v-if='picassoIsInitialized && picasso != null' :currentTime="picasso.relativeTime"
            :duration="picasso.totalRelativeDuration" :playing="!picasso.paused" :muted="picasso.muted"
            :isFullScreen="picasso.fullscreen" @play="togglePlay($event)" @seek="seek($event)" @mute="mute($event)"
            @toggleFullScreen="toggleFullScreen" />
        <div id="picasso_container" ref="picasso_container" allowfullscreen>
        </div>

    </div>
</template>

<script>
import axios from "axios"
import Picasso from "@yopbox/picasso"
import Controls from "./components/Controls/Controls"

//STORE
import Vuex from "vuex";
import store from "src/store/index";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default {
    components: { Controls },
    emits: ['currentTimeChange', "ready", "textOverlaySelected", "textOverlayTransformed"],
    data() {
        return {
            picasso: null,
            picassoIsInitialized: false,
            picassoIsReady: false,
            mediaLoading: null,
            playerPicassoFullScreenStyle: "display : flex; align-items : center; justify-content:center",
            currentRelativeTime: 0
        }
    },
    store : store,
    props: [
        "optimization_progression",
        "media",
        "clip_id",
        "clip_data",
        "background_color"
    ],
    watch: {
        media() {
            this.init()
        },
        async "project_options.format"(format_value) {
            this.$nextTick(() => {
                this.picassoIsReady = false;
                this.picasso.setFormat(format_value);
                this.picasso.initOverlays();
            })
        },
        async "project_options.cut_level"() {
            try {
                await this.fetchClipData(this.clip_data._id)
                await this.load_cuts()
            } catch (e) {
                console.log(e)
            }
        },
        async "project_options.auto_crop"() {
            try {
                await this.fetchClipData(this.clip_data._id)
                await this.load_cuts()
            } catch (e) {
                console.log(e)
            }
        },

        async "project_options.zoom"() {
            try {
                await this.fetchClipData(this.clip_data._id)
                await this.load_cuts()
            } catch (e) {
                console.log(e)
            }
        },
        "project_options.captions_settings"(value) {
            try {
                if (this.picasso != null) {
                    this.picassoIsReady = false
                    this.picasso.setCaptionsSettings(value)
                }
            } catch (e) {
                console.log(e)
            }
        },
        "picasso.currentMediaTime": function (val) {
            this.$emit('currentTimeChange', val)
        },
        async project_language(){
            await this.fetchClipData(this.clip_data._id)
            this.picasso.setTranscription(this.clip_data.transcription)
        }
    },
    async mounted() {
        try {
            if (this.media != null) {
                this.init()
            }
        } catch (e) {
            console.log(e)
        }
    },

    methods: {
        ...Vuex.mapActions(["fetchClipData"]),
        seekRealTime(value) {
            this.picasso.seekRealTime(value)
        },
        async init() {
            try {

                if (this.picasso != null) {
                    this.picasso.destroy();
                    this.picasso = null;
                }

                //document.addEventListener("keydown", this.handle_keyboardActions)
                window.addEventListener("resize", this.handle_windowResize);

                //RESET
                let clip_data = await this.load_cuts();
                if (clip_data.overlays != undefined && clip_data.overlays.assets != undefined) {
                    clip_data.overlays.assets = await this.getOverlaysMediaUrl(clip_data.overlays.assets);
                }
                if (clip_data.overlays.texts == undefined){
                    clip_data.overlays.texts = []
                }


                if (clip_data != null) {
                    this.$nextTick(() => {
                        //this.picasso = new Picasso('picasso_container', media_url_presigned, clip_data, this.project_options.format, { width: this.media.media_width, height: this.media.media_height }, media_type, this.background_color)
                        this.picasso = new Picasso('picasso_container', this.project_options.format, clip_data, this.background_color, this.project_options.captions_settings, {
                            "videoSrc": this.media_url,
                            "originalSize": {
                                width: this.media.media_width,
                                height: this.media.media_height
                            },
                            "media_type": this.media.media_type,
                            "transcription": clip_data.transcription
                        },
                            false,
                            clip_data.overlays)

                        this.picasso.on('initialized', () => {
                            this.picassoIsInitialized = true
                            this.picassoIsReady = true;
                            this.$emit('ready');

                        });

                        this.picasso.on('ready', () => {
                            this.picassoIsReady = true;
                            this.$emit('ready');
                        });

                        this.picasso.on('textOverlaySelected', (textOverlay) => {
                            this.$emit('textOverlaySelected', textOverlay)
                        });

                        this.picasso.on('textOverlayTransformed', (textOverlay) => {
                            this.$emit('textOverlayTransformed', textOverlay)
                        });

                        


                        window.picasso = this.picasso


                    })
                }

            } catch (e) {
                console.error(e)
            }
        },
        async load_cuts() {
            this.picassoIsReady = false
            let max_loop = 20

            //console.log('Requested', i)
            //let clip_data = this.clip_data//await axios.get('/projects/' + this.$store.state.creativeSpace.project_data._id + '/timeline/' + this.clip_id)
            //console.log("res", i)
            let editing_mode_used_in_cuts = null
            if (this.clip_data.subClips != undefined && this.clip_data.subClips[0] != undefined) {
                editing_mode_used_in_cuts = this.clip_data.subClips[0].cut_level
            }

            let editing_mode_match = (editing_mode_used_in_cuts == this.project_options.cut_level)
            let auto_crop_match = (this.project_options.auto_crop == this.clip_data.auto_crop)

            while (max_loop > 0 && (!editing_mode_match || !auto_crop_match)) {
                max_loop -= 1;
                await sleep(500);

                //console.log('Requested', i)
                //res = await axios.get('/projects/' + this.$store.state.creativeSpace.project_data._id + '/timeline/' + this.clip_id)
                await this.fetchClipData(this.clip_data._id)
                //console.log("res", i)
                if (this.clip_data.subClips != undefined && this.clip_data.subClips[0] != undefined) {
                    editing_mode_used_in_cuts = this.clip_data.subClips[0].cut_level
                }

                editing_mode_match = (editing_mode_used_in_cuts == this.project_options.cut_level)
                auto_crop_match = (this.project_options.auto_crop == this.clip_data.auto_crop)
            }

            if (editing_mode_match && auto_crop_match) {
                if (this.picasso == null) {
                    return {
                        subClips: this.clip_data.subClips,
                        relative_duration: this.clip_data.relative_duration,
                        transcription: this.clip_data.transcription,
                        overlays: this.clip_data.overlays
                    }
                } else {
                    this.picasso.setMakerData({
                        subClips: this.clip_data.subClips,
                        relative_duration: this.clip_data.relative_duration
                    })
                    this.picasso.setTranscription(this.clip_data.transcription)
                }
            } else {
                if (!auto_crop_match) {
                    console.error("auto_crop state in cuts don't match with project settings")
                }
                if (!editing_mode_match) {
                    console.error("Editing_mode don't match with project editing mode", "editing_mode_used_in_cuts", editing_mode_used_in_cuts, "in project options", this.project_options.cut_level)
                }
                return null
            }
        },
        togglePlay(value) {
            if (value == true) {
                this.picasso.playPlayer()
            } else {
                this.picasso.pausePlayer()
            }

        },
        seek(value) {
            this.picasso.seek(value)
            // this.$refs.video.currentTime = value
        },
        toggleFullScreen(activated) {
            if (activated){
                this.picasso.setFullscreen()
            } else {
                document.exitFullscreen() 
                //this.picasso.exitFullScreen()
            }
        },
        mute() {
            this.picasso.setMuted()
        },
        handle_keyboardActions(e) {
            if (e.code == "Space" && e.target == document.body) {
                e.preventDefault();
                if (this.picasso.paused) {
                    this.picasso.playPlayer();
                } else {
                    this.picasso.pausePlayer();
                }
            }
            else if (e.code == "ArrowRight") {
                if (this.picasso.relativeTime + 1000 < this.picasso.totalRelativeDuration) {
                    this.picasso.seek(this.picasso.relativeTime + 40)
                } else {
                    this.picasso.pausePlayer()
                    this.picasso.seek(this.picasso.totalRelativeDuration)
                }
            } else if (e.code == "ArrowLeft") {
                if (this.picasso.relativeTime - 1000 > 0) {
                    this.picasso.seek(this.picasso.relativeTime - 40)
                } else {
                    this.picasso.seek(0)
                }
            } else if (e.code == "KeyF") {
                //this.picasso.setFullscreen()
            }

        },
        handle_windowResize() {
            if (!this.picasso.fullscreen) {
                this.picasso.setSize();
            } else {
                if (!document.fullscreenElement) {
                    this.picasso.fullscreen = false
                }
            }
        },

        async getOverlaysMediaUrl(assets) {
            if (assets == undefined) {
                return []
            };

            let newAssets = await Promise.all(assets.map(asset => {
                return new Promise(async (resolve) => {
                    let set = JSON.parse(JSON.stringify(asset));
                    if (set.origin == "user_media" && set.media_id != null) {
                        set.media_url = await axios({
                            methods: "get",
                            url: `medias/${set.media_id}/url`,
                        })
                            .then(async (res) => {
                                if (/image/.test(res.data.media_mimetype)) {
                                    return await axios({
                                        methods: "get",
                                        url: `storage/redirect/${res.data.media_path}`,
                                        //params : { stream : true}
                                    })
                                        .then((res) => {
                                            //console.log(res.data)
                                            return res.data;
                                        })
                                        .catch(() => {
                                            return null;
                                        });
                                } else {
                                    return res.data.media_url;
                                }
                            })
                            .catch(() => {
                                return null;
                            });
                    } else if (set.origin == "giphy" || set.origin == "pexels") {
                        set.media_url = set.static_url;
                    }
                    resolve(set)

                })
            }))

            return newAssets

        },

        async initOverlays(params) {
            params.assets = await this.getOverlaysMediaUrl(params.assets);
            this.picasso.initOverlays(params);
        }
    },
    computed: {
        ...Vuex.mapGetters([
            'project_options',
            "in_saving_mode"
        ]),
        media_url() {
            var media_url;
            if (this.media != null && this.media.media_sound_enhanced_url != null && this.project_options.audio_enhanced) {
                media_url = this.media.media_sound_enhanced_url;
            } else {
                media_url = this.media.media_url;
            }

            return media_url
        },
        isPlaying(){
            if (this.picasso != null){
                return !this.picasso.paused
            } else {
                return false
            }
        },
        project_language(){
            return this.$store.state.creativeSpace.project_data.project_language
        },

    },
    unmounted() {
        try {
            window.removeEventListener('resize', this.handle_windowResize)
            //document.removeEventListener("keydown", this.handle_keyboardActions)
            if (this.picasso != null) {
                this.picasso.destroy()
            }
        } catch (e) {
            console.log(e)
        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>

<style lang="scss" scoped src="./style.scss"></style>