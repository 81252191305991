<template>
    <div class="transcription_container">
        <!-- FLYING MENU -->
        <div v-if="textSplited != null"
            v-show="transcription != null && selectedTextIndex.length != 0 && hideFlyingMenu == false && picassoIsPlaying == false && !changingOverlayBrackets" id="flyingMenu"
            ref="flyingMenu">
            <button v-if="!textSplited[selectedTextIndex[0]]?.hidden && selectedTextIndex.length == 1"
                class="flyingMenu_button_container" @click.stop="editWord">
                <img src="../../../../../../../../assets/pen.svg" /><a>{{ $t('Edit') }}</a>
            </button>
            <button v-if="!textSplited[selectedTextIndex[0]]?.hidden" class="flyingMenu_button_container"
                @click.stop="addOrRemoveText('remove')">
                <img src="../../../../../../../../assets/remove.svg" /><a>{{ $t('Remove') }}</a>
            </button>
            <button v-else class="flyingMenu_button_container" @click.stop="addOrRemoveText('add')">
                <img src="../../../../../../../../assets/reset.png" /><a>{{ $t('Undo') }}</a>
            </button>

            <button v-if="!textSplited[selectedTextIndex[0]]?.hidden && selectedTextIndex.length > 1" class="flyingMenu_button_container" @click.stop="mergeWords">
                <img src="../../../../../../../../assets/merge.png" /><a>{{
                    $t('CreativeSpace.transcription.merge') }}</a>
            </button>

            <button v-if="selectedTextIndex.length == 1 && textSplited[selectedTextIndex].subWords != null"
                class="flyingMenu_button_container" @click.stop="unmergeWords">
                <img src="../../../../../../../../assets/unmerge.png" /><a>{{
                    $t('CreativeSpace.transcription.unMerge') }}</a>
            </button>

            <button v-if="!textSplited[selectedTextIndex[0]]?.hidden" class="flyingMenu_button_container" @click.stop="createClip">
                <img src="../../../../../../../../assets/video_media_blue.png" /><a>{{
                    $t('CreativeSpace.transcription.createClip')
                }}</a>
            </button>

            <button class="flyingMenu_button_container" @click.stop="   addOverlay" v-if="!textSplited[selectedTextIndex[0]]?.hidden && overlayStartBracketWordIndex == -1">
                <img src="../../../../../../../../assets/plus-circle.svg" /><a>{{ $t('overlays.add_overlay') }}</a>
            </button>

            <!-- OVERLAY SETTINGS-->
            <template v-if="currentOverlayAssetSelected != null">
                <span class="separator"></span>

                <button class="flyingMenu_button_container" @click.stop="deleteOverlay" style="color: #3d3393;">
                    <img src="../../../../../../../../assets/remove_purple.svg" /><a>{{ $t('overlays.delete_overlay') }}</a>
                </button>

                <button class="flyingMenu_button_container"
                    v-if="currentOverlayAssetSelected.type == 'video' && currentOverlayAssetSelected.origin != 'giphy'"
                    @click.stop="changeOverlayTrim" style="color: #3d3393;">
                    <img src="../../../../../../../../assets/start.png" /><a>{{ $t('overlay.trim_start') }}</a>
                </button>

                <button class="flyingMenu_button_container" v-if="currentOverlayAssetSelected.position == 'fit'"
                    @click.stop="changeOverlayPosition('fill')" style="color: #3d3393;">
                    <img src="../../../../../../../../assets/overlay-fill.svg" /><a>{{ $t('overlay.fill') }}</a>
                </button>

                <button class="flyingMenu_button_container" v-if="currentOverlayAssetSelected.position == 'fill'"
                    @click.stop="changeOverlayPosition('fit')" style="color: #3d3393;">
                    <img src="../../../../../../../../assets/overlay-fit.svg" /><a>{{ $t('overlay.fit') }}</a>
                </button>
            </template>

            <!-- OVERLAY SETTINGS-->
            <template v-if="currentOverlayTextSelectingIndex != -1">
                <span class="separator"></span>

                <button class="flyingMenu_button_container" @click.stop="editTextOverlay" style="color: #c37f18;">
                    <img src="../../../../../../../../assets/pen_yellow.svg" /><a>{{ $t('Modify') }}</a>
                </button>


                <button class="flyingMenu_button_container" @click.stop="deleteOverlay" style="color: #c37f18;">
                    <img src="../../../../../../../../assets/remove_yellow.svg" /><a>{{ $t('overlays.delete_overlay') }}</a>
                </button>
            </template>

        </div>


        <!-- WORDS | SILENCES | OVERLAYS-BRACKETS -->

        <div v-if="transcription != null && piccasoIsReady == true" v-click-outside="unselect_word" id="word_container" :style="bracketsColorStyle"
            @mouseover="word_hover" @focusout="word_blur" @mousedown="selectedTextIndex = []; selecting = true"
            @mouseup.stop="mouseUpOnTranscriptionElement($event)" :class="[
                { 'selecting': selecting && !changingOverlayBrackets },
                { 'changingBracket': changingOverlayBrackets }
            ]">

            <span v-for="(word, index) in textSplited" :id="'word_' + index" :word_index="index" :key="index" class="word"
                :el_type="word.type" :hidden="word.hidden" :class="[
                    { 'selectedText': selectedTextIndex.includes(index) && indexWordEdition != index },
                    { 'highlightedText': highlightedWordIndex == index },
                    { 'hiddenWord': this.textSplited[index]?.hidden },
                    { 'editing_word': indexWordEdition == index },
                    { 'overlays': word.overlays != null },
                    { 'overlaysTexts': word.overlaysTexts != null }
                ]" :contentEditable="indexWordEdition == index">

                <div v-if="word.type == 'silence' && word.hidden" class="silenceHidden">
                    <img src="../../../../../../../../assets/reset.png" alt="" class="undoHiddenSilence"
                        @click.stop="undoSilence" />
                    <span class="silenceHidden_icon">[...]</span>
                </div>

                <span v-else-if="word.type == 'silence' && !word.hidden">▪ </span>
                <span v-else>{{ word.word != "" ? word.word + ' ' : '* ' }}</span>
            </span>

        </div>

        <!-- WORDS LOADING -->
        <div v-else id="word_container">
            <div class="loading-content"></div>
            <div class="loading-content"></div>
            <div class="loading-content"></div>
            <div class="loading-content"></div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import Vuex from "vuex";

function insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}

export default {
    props: [
        "transcriptionData",
        "currentTime",
        "media_id",
        "project_id",
        "clip_id",
        "overlays",
        "picassoIsPlaying",
        "piccasoIsReady"
    ],
    emits: ["seek", "reload_cuts", "addOverlay", "reloadOverlays", "editOverlay", "overlayTimingChanged"],
    data() {
        return {
            transcription: null,
            indexWordEdition: null,
            selectedTextIndex: [],
            selecting: false,
            hideFlyingMenu: false,
            textSplited: [],
            highlightedWordIndex: -1,
            indexWordHover: -1,
            currentOverlaySelectingIndex: -1, //When selecting words at time of an overlay
            overlayStartBracketWordIndex: -1, //Current word index where the overlay starts
            overlayEndBracketWordIndex: -1, //Current word index where the overlay ends
            changingOverlayBracketIn: -1, //Word index where mouse is over, for change starting time of current overlay selected
            changingOverlayBracketOut: -1,  //Word index where mouse is over, for change ending time of current overlay selected
            overlaysBracketsEls: [],

            currentOverlayTextSelectingIndex : -1
        }
    },
    watch: {
        transcriptionData: {
            immediate: true,
            handler(value) {
                if (value != null) {
                    this.transcription = JSON.parse(JSON.stringify(value));
                    this.renderTextSplitted()
                }

            }
        },
        toolBarIsOpen(val) {
            if (val == true) {
                this.hideFlyingMenu = true
            }
        },
        currentTime() {
            this.highlightedWordIndex = this.textSplited.findIndex(word => (this.currentTime >= word.start && this.currentTime < word.end))
        },
    },
    mounted() {
        document.getElementById('project_timeline').addEventListener('scroll', this.onScroll, false);
        document.getElementById('word_container').addEventListener('scroll', this.onScroll, false);
    },
    computed: {
        ...Vuex.mapGetters(['toolBarIsOpen']),
        currentOverlayAssetSelected() {
            if (this.currentOverlaySelectingIndex != -1){
                return this.overlays.assets[this.currentOverlaySelectingIndex];
            } else {
                return null
            }
        },
        changingOverlayBrackets() {
            return (this.changingOverlayBracketIn != -1 || this.changingOverlayBracketOut != -1);
        },
        bracketsColorStyle(){
            if (this.currentOverlayTextSelectingIndex != -1){
                return "--bracketOverlayColor : var(--overlayTextAnchorColor)"
            } else {
                return "--bracketOverlayColor : var(--overlayAssetAnchorColor)"
            }
        }
    },
    methods: {
        ...Vuex.mapMutations(['SHOW_CATALOG', "SAVING_MODE"]),
        ...Vuex.mapActions(['addClipFromTranscription', 'putClipData', "complete_onboarding_task"]),
        renderTextSplitted() {
            if (this.transcription == null) {
                return;
            }

            let transcriptionDisplay = [];
            let lastElementIntranscription = null

            //console.log('renderSplittedText', this.transcription[25])
            this.transcription.forEach((word) => {
                const wordfozen = JSON.parse(JSON.stringify(word)) 
                //Silence hidden
                if (wordfozen.type == "silence" && wordfozen.hidden) {
                    //If first element in transcriptionDisplay
                    if (lastElementIntranscription == null) {
                        lastElementIntranscription = wordfozen
                        transcriptionDisplay.push(wordfozen);
                    } else {
                        if (lastElementIntranscription.type == "silence" && lastElementIntranscription.hidden) {
                            lastElementIntranscription.end = wordfozen.end;
                            lastElementIntranscription.duration += wordfozen.duration
                        } else {
                            lastElementIntranscription = wordfozen
                            transcriptionDisplay.push(wordfozen);
                        }
                    }
                } else {
                    lastElementIntranscription = wordfozen
                    transcriptionDisplay.push(wordfozen);
                }

            });

            transcriptionDisplay.forEach(word => {
                //console.log(el)
                let overlays_assets = this.overlays.assets.map((asset, index) => {
                    if (asset.start <= word.start && word.end <= asset.end) {
                        return index
                    } else {
                        return null
                    }
                }).filter(res => res != null)

                if (overlays_assets.length > 0) {
                    word.overlays = overlays_assets
                } else {
                    word.overlays = null
                }

                if (this.overlays.texts == undefined){
                    word.overlaysTexts = null;
                    return;
                }

                let overlays_texts = this.overlays.texts.map((text, index) => {
                    if (text.start <= word.start && word.end <= text.end) {
                        return index
                    } else {
                        return null
                    }
                }).filter(res => res != null)

                if (overlays_texts.length > 0) {
                    word.overlaysTexts = overlays_texts
                } else {
                    word.overlaysTexts = null
                }
            })

            //console.log('renderSplittedText transcriptionDisplay', transcriptionDisplay[4])
            //console.log('---')
            this.textSplited = JSON.parse(JSON.stringify(transcriptionDisplay));

            //console.log(this.textSplited)
        },
        onScroll() {
            if (this.hideFlyingMenu == false) {
                this.hideFlyingMenu = true
            }
        },
        word_hover(event) {
            /*
            if (this.changingOverlayBracketIn == -1 && this.changingOverlayBracketOut == -1){
                return;
            }
            */
            if (event.target.getAttribute("el_type") == "overlay_bracket") {
                return;
            }

            if (event.target.hasAttribute('hidden') || event.target.parentNode.hasAttribute('hidden') || event.target.parentNode.parentNode.hasAttribute('hidden')) {
                return;
            }

            var index, nodeEl = null;
            if (event.target.parentNode.getAttribute('el_type') == "word" || event.target.parentNode.getAttribute('el_type') == "silence") {
                index = event.target.parentNode.getAttribute('word_index');
                nodeEl = event.target.parentNode;
            } else {
                index = event.target.getAttribute('word_index');
                nodeEl = event.target;
            }

            var elementIndex = -1;
            if (index != null && /^\d+$/.test(index)) {
                elementIndex = Number(index)
            }

            this.indexWordHover = elementIndex;

            if (this.changingOverlayBracketIn != -1) {
                this.removeBracketElments();

                if (elementIndex != -1) {
                    this.changingOverlayBracketIn = elementIndex;
                    this.addBracket("in", elementIndex, nodeEl)
                }
            } else if (this.changingOverlayBracketOut != -1) {

                this.removeBracketElments();
                if (elementIndex != -1) {
                    this.changingOverlayBracketOut = elementIndex;
                    this.addBracket("out", elementIndex, nodeEl)
                }
            }

        },
        addBracket(in_out, wordIndex, nodeEl) {

            const id = `bracket-${in_out}-${wordIndex}`;

            //Verify if bracket already exist
            let el = this.overlaysBracketsEls.find((bracketEl) => { return (bracketEl.getAttribute('id') == id) })

            if (el == undefined) {
                if (nodeEl == undefined) {
                    nodeEl = document.getElementById(`word_${wordIndex}`)
                }
                let bracketEl = document.createElement("span");
                bracketEl.setAttribute("id", id)
                bracketEl.setAttribute("el_type", "overlay_bracket");
                bracketEl.setAttribute("position", in_out);
                bracketEl.innerText = (in_out == "in" ? "[" : "]");
                bracketEl.classList.add("overlay_anchor");
                bracketEl.setAttribute('word_index', wordIndex);

                this.overlaysBracketsEls.push(bracketEl)

                let parentDiv = document.getElementById('word_container');
                if (parentDiv == undefined) {
                    return;
                }

                if (in_out == "in") {
                    parentDiv.insertBefore(bracketEl, nodeEl);
                    bracketEl.addEventListener("mousedown", () => {
                        this.changingOverlayBracketIn = wordIndex;
                    })
                } else {
                    insertAfter(bracketEl, nodeEl);
                    bracketEl.addEventListener("mousedown", () => {
                        this.changingOverlayBracketOut = wordIndex;
                    })
                }
            }
        },
        /**
            Remove bracket html node elements
        */
        removeBracketElments(all) {
            this.overlaysBracketsEls = this.overlaysBracketsEls.map(bracketEl => {
                if (all == true) {
                    bracketEl.remove();
                    return null;
                }

                //Treat all "out" brackets
                if (bracketEl.getAttribute('position') == "out") {
                    //If currently changing overlay bracket in, don't delete bracket out
                    if (this.changingOverlayBracketIn != -1) {
                        if (this.overlayEndBracketWordIndex != Number(bracketEl.getAttribute('word_index'))) {
                            bracketEl.remove();
                            return null;
                        }
                    } else {
                        bracketEl.remove();
                        return null;
                    }
                }

                //Treat all "in" brackets
                else if (bracketEl.getAttribute('position') == "in") {
                    //If currently changing overlay bracket out, don't delete bracket in
                    if (this.changingOverlayBracketOut != -1) {
                        if (this.overlayStartBracketWordIndex != Number(bracketEl.getAttribute('word_index'))) {
                            bracketEl.remove();
                            return null;
                        }
                    } else {
                        bracketEl.remove();
                        return null;
                    }
                }

                return bracketEl;
            }).filter(e => e != null)



        },

        clearOverlaysBracket() {
            this.overlayStartBracketWordIndex = -1;
            this.overlayEndBracketWordIndex = -1;
            this.currentOverlaySelectingIndex = -1;
            this.currentOverlayTextSelectingIndex = -1;
            this.removeBracketElments(true)
        },

        mouseUpOnTranscriptionElement() {
            this.hideFlyingMenu = false;
            this.selecting = false;

            if (this.changingOverlayBrackets == true) {

                var overlayIndex, overlayType;
                if (this.currentOverlayTextSelectingIndex != -1){
                    overlayIndex = this.currentOverlayTextSelectingIndex;
                    overlayType = "text";
                } else {
                    overlayIndex = this.currentOverlaySelectingIndex;
                    overlayType = "asset";
                }

                if (this.changingOverlayBracketIn != -1) {
                    const startTimeWordIndex = this.retrieveIndexInTranscription(this.changingOverlayBracketIn)
                    this.changeOverlayTiming(overlayIndex, { start: this.transcription[startTimeWordIndex].start }, overlayType)
                    this.overlayStartBracketWordIndex = this.changingOverlayBracketIn;
                }

                if (this.changingOverlayBracketOut != -1) {
                    const endTimeWordIndex = this.retrieveIndexInTranscription(this.changingOverlayBracketOut)
                    this.changeOverlayTiming(overlayIndex, { end: this.transcription[endTimeWordIndex].end }, overlayType);
                    this.overlayEndBracketWordIndex = this.changingOverlayBracketOut;
                }

                this.changingOverlayBracketIn = -1;
                this.changingOverlayBracketOut = -1;
                this.unselect_word();
                return;
            } else {
                this.selectWord()
            }
        },

        selectWord() {
            this.clearOverlaysBracket();

            const selection = window.getSelection()
            if (selection.anchorNode == null || selection.focusNode == null) {
                return
            }

            const startNode = (selection.anchorNode.parentNode.parentNode.hasAttribute("word_index") ? selection.anchorNode.parentNode.parentNode : selection.anchorNode.parentNode);
            const endNode = (selection.focusNode.parentNode.parentNode.hasAttribute("word_index") ? selection.focusNode.parentNode.parentNode : selection.focusNode.parentNode);

            this.$nextTick(() => {
                this.replaceFlyingMenu(endNode);
            })

            const startWordIndex = Number(startNode.getAttribute("word_index"));
            const endWordIndex = Number(endNode.getAttribute("word_index"))

            const direction = startWordIndex <= endWordIndex ? 1 : -1

            this.selectedTextIndex = [];
            let startFor = direction == 1 ? startWordIndex : endWordIndex
            let endFor = direction == 1 ? endWordIndex : startWordIndex;

            for (let i = startFor; i <= endFor; i++) {
                if (this.textSplited[i].type == 'word' || this.textSplited[i].type == 'silence') {
                    this.selectedTextIndex.push(Number(i))
                }
            }

            if (this.textSplited[startFor] != undefined && this.textSplited[startFor].start != undefined && this.textSplited[startFor].hidden != true) {
                this.$emit("seek", this.textSplited[startFor].start)
            } else {
                return;
            }

            //Display overlays bracket
            let startTimeSelection = this.textSplited[startFor].start;
            let endTimeSelection = this.textSplited[endFor].end;
            var overlayFound = false;
            var overlayFound_assetIndex = -1;
            var overlayTextFound = false;
            var overlayTextFound_assetIndex = -1;

            this.overlays.assets.forEach((asset, assetIndex) => {
                if (overlayFound == true) {
                    return;
                }

                if (startTimeSelection >= asset.start && startTimeSelection < asset.end) {
                    overlayFound = true;
                }


                if (asset.start == startTimeSelection) {
                    overlayFound = true;
                }
                if (asset.end == endTimeSelection) {
                    overlayFound = true;
                }


                if (overlayFound == true) {
                    overlayFound_assetIndex = assetIndex;
                }

            });

            if (overlayFound == true) {
                this.currentOverlaySelectingIndex = overlayFound_assetIndex;
                var asset = this.overlays.assets[overlayFound_assetIndex];
                
                this.overlayStartBracketWordIndex = this.textSplited.findIndex(w => w.start >= asset.start);
                this.overlayEndBracketWordIndex = this.textSplited.findLastIndex(w => w.end <= asset.end);

                this.$nextTick(() => {
                    this.addBracket("in", this.overlayStartBracketWordIndex);
                    this.addBracket("out", this.overlayEndBracketWordIndex)
                })
            }

            if (this.overlays.texts != undefined){
                this.overlays.texts.forEach((text, assetIndex) => {
                    if (overlayTextFound == true) {
                        return;
                    }
    
                    if (startTimeSelection >= text.start && startTimeSelection < text.end) {
                        overlayTextFound = true;
                    }
    
    
                    if (text.start == startTimeSelection) {
                        overlayTextFound = true;
                    }
                    if (text.end == endTimeSelection) {
                        overlayTextFound = true;
                    }
    
    
                    if (overlayTextFound == true) {
                        overlayTextFound_assetIndex = assetIndex;
                    }
    
                });
    
                if (overlayTextFound == true) {
                    this.currentOverlayTextSelectingIndex = overlayTextFound_assetIndex;
                    let overlayText = this.overlays.texts[overlayTextFound_assetIndex];
                    
                    this.overlayStartBracketWordIndex = this.textSplited.findIndex(w => w.start >= overlayText.start);
                    this.overlayEndBracketWordIndex = this.textSplited.findLastIndex(w => w.end <= overlayText.end);
    
                    this.$nextTick(() => {
                        this.addBracket("in", this.overlayStartBracketWordIndex);
                        this.addBracket("out", this.overlayEndBracketWordIndex)
                    })
                }
            }
            
            if (this.indexWordEdition == null) {
                this.clearNativeSelection()
            }
        },

        replaceFlyingMenu(node){
                const nodePos = node.getBoundingClientRect();
                var flyingMenu = document.getElementById('flyingMenu').getBoundingClientRect();
                this.$refs.flyingMenu.style.top = nodePos.y - flyingMenu.height - 6 + 'px'
                this.$refs.flyingMenu.style.left = nodePos.x + (nodePos.width / 2) - flyingMenu.width / 2 - 70 + 'px' //-70px because the Left NavBar of page;
        },

        unselect_word() {
            this.clearNativeSelection();
            this.selectedTextIndex = [];

        },
        clearNativeSelection() {
            if (window.getSelection) {
                if (window.getSelection().empty) {  // Chrome
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {  // Firefox
                    window.getSelection().removeAllRanges();
                }
            } else if (document.selection) {  // IE?
                document.selection.empty();
            }
        },
        splitText(text) {
            let textArray = text.split(' ')

            textArray.forEach((text, idx) => {
                if (text.length == 1 && !this.isCharLetter(text) && !/\d/.test(text)) {
                    textArray[idx - 1] = textArray[idx - 1] + ' ' + textArray[idx]
                    textArray.splice(idx, 1);
                }
            });
            return textArray
        },
        removePunctuation(text) {
            text = text.replace(/\.|\,|\!|\;|\:/g, '')
            text = text.toLowerCase()
            return text
        },
        isCharLetter(char) {
            return char.toLowerCase() !== char.toUpperCase();
        },
        async addOrRemoveText(action = 'add', reload_cuts = true) {
            this.complete_onboarding_task('remove_part_of_clip');
            //Search transcription words/silences from selectedText index (no same because silence wrapped)
            const wordWordSelected = this.selectedTextIndex[0]
            const lastWordSelected = this.selectedTextIndex[this.selectedTextIndex.length - 1]

            const startTimeSelection = this.textSplited[wordWordSelected].start
            const endTimeSelection = this.textSplited[lastWordSelected].end

            let indexEquivalentInTrans = [];
            
            this.transcription.forEach((w, idxInTrans) =>{
                if (startTimeSelection <= w.start && w.end <= endTimeSelection){
                    indexEquivalentInTrans.push(idxInTrans);
                    this.transcription[idxInTrans].hidden = (action == 'add' ? false : true);
                }
            })


            //this.renderTextSplitted();
            if (reload_cuts == true) {
                this.unselect_word();
                this.clearOverlaysBracket();
            }
            await this.updateTranscription(reload_cuts);
            return indexEquivalentInTrans
        },
        async updateTranscription(reload_cuts = true) {
            await this.putClipData({ _id: this.clip_id, transcription: this.transcription });
            if (reload_cuts == true) {
                this.$emit('reload_cuts');
            }
        },
        selectElement(element) {
            if (window.getSelection) {
                var sel = window.getSelection();
                sel.removeAllRanges();
                var range = document.createRange();
                range.selectNodeContents(element);
                sel.addRange(range);
            } else if (document.selection) {
                var textRange = document.body.createTextRange();
                textRange.moveToElementText(element);
                textRange.select();
            }
        },
        editWord() {
            this.indexWordEdition = this.selectedTextIndex[0];
            let element = document.getElementById(`word_${this.indexWordEdition}`);

            this.unselect_word();
            //this.clearOverlaysBracket();
            this.$nextTick(() => {
                this.selectElement(element);
                let text = $(element).text();
                element.textContent = ""

                if (this.textSplited[this.indexWordEdition].type == "word") {
                    element.textContent = text
                }

            })

            $(element).on('blur', () => {
                let newWordValue = $(element).text();
                if (newWordValue == "") {
                    element.textContent = "* "
                } else if (newWordValue.slice(-1) != " ") {
                    element.textContent = newWordValue + " "
                }
            

                if (this.indexWordEdition != null) {
                    const index = this.transcription.findIndex(w => w.start == this.textSplited[this.indexWordEdition].start && w.end == this.textSplited[this.indexWordEdition].end)
                    if (newWordValue.trim() == "") {
                        delete this.transcription[index].word
                        this.transcription[index].type = "silence"
                    } else {
                        this.transcription[index].type = "word"
                        this.transcription[index].word = newWordValue.trim();
                    }
                    this.updateTranscription().then(() => { this.indexWordEdition = null })

                }

            })

            $(element).on('keydown', (evt) => {
                if (evt.code == "Enter") {
                    evt.stopPropagation();
                    $(element).blur()
                }
            })

        },
        async mergeWords() {
            let array = this.textSplited.slice(this.selectedTextIndex[0], this.selectedTextIndex[this.selectedTextIndex.length - 1] + 1)
            const wordPresent = array.findIndex(w => w.type == 'word') != -1

            let new_word = {
                type: wordPresent ? 'word' : 'silence',
                start: array[0].start,
                end: array[array.length - 1].end,
                word: wordPresent ? "" : undefined,
                subWords: []
            }
            array.map((w) => {
                if (w.subWords != undefined) {
                    new_word.subWords.push(...w.subWords)
                } else {
                    new_word.subWords.push(w)
                }

                if (w.type == 'word') {
                    new_word.word += w.word + " "
                }
            })
            if (new_word.type == 'word') {
                new_word.word = (new_word.word).trim()
            }

            const start_index = this.transcription.findIndex(w => w.start == array[0].start && w.end == array[0].end)
            const end_index = this.transcription.findIndex(w => w.end == array[array.length - 1].end && w.end == array[array.length - 1].end)
            this.transcription.splice(start_index, end_index - start_index + 1, new_word)
            this.selectedTextIndex = [this.selectedTextIndex[0]]

            await this.updateTranscription()
        },
        async unmergeWords() {
            const word = this.textSplited[this.selectedTextIndex[0]]

            const index = this.transcription.findIndex(w => w.start == word.start && w.end == word.end)
            if (index == -1) {
                return
            }
            this.transcription.splice(index, 1, ...word.subWords)
            await this.updateTranscription()
        },
        async undoSilence(event) {
            const index = event.target.parentNode.parentNode.getAttribute('word_index');
            const silence = this.textSplited[index];
            const start_index = this.transcription.findIndex(w => w.start == silence.start)
            const end_index = this.transcription.findIndex(w => w.end == silence.end)
            console.log(index, silence, start_index, end_index)
            for (let i = start_index; i <= end_index; i++) {
                this.transcription[i].hidden = false;         
            }
            
            await this.updateTranscription(true)

        },
        async createClip() {
            const selectedIndexInTrans = await this.addOrRemoveText('remove', false)
            this.complete_onboarding_task('sliceVideo');
            this.addClipFromTranscription({ clip_id_src: this.clip_id, startWordIdx: selectedIndexInTrans[0], endWordIdx: selectedIndexInTrans[selectedIndexInTrans.length - 1] })
        },

        retrieveIndexInTranscription(wordIdx) {
            const word = this.textSplited[wordIdx];
            return this.transcription.findIndex(w => w.start == word.start && w.end == word.end)
        },

        addOverlay() {
            this.complete_onboarding_task('add_overlay');
            const startWordIdx = this.retrieveIndexInTranscription(this.selectedTextIndex[0]);
            const endWordIdx = this.retrieveIndexInTranscription(this.selectedTextIndex[this.selectedTextIndex.length - 1]);
            const startWord = this.transcription[startWordIdx];
            const endWord = this.transcription[endWordIdx];
            var text;
            try {
                text = this.transcription.filter((w, wIdx) => startWordIdx <= wIdx && wIdx <= endWordIdx).map(w => w.word).join(' ').replace(/^\s+|\s+$|\s+(?=\s)/g, "");
            } catch {
                text = undefined;
            }
            this.$emit('addOverlay', { start: Number(startWord.start), end: Number(endWord.end), text });
            this.unselect_word();
            this.clearOverlaysBracket();
        },

        changeOverlayTrim() {
            this.$emit('editOverlay', {
                overlayType : "asset",
                ...this.overlays.assets[this.currentOverlaySelectingIndex]
            })
            this.unselect_word();
            this.clearOverlaysBracket();
        },

        changeOverlayTiming(overlayAssetIndex, data, overlayType) {
            var overlayKey = (overlayType == "asset" ? "assets" : "texts")

            var start;
            var end;

            if (data.start != undefined) {
                start = Number(data.start);
            } else {
                start = Number(this.overlays[overlayKey][overlayAssetIndex].start);
            }

            if (data.end != undefined) {
                end = Number(data.end);
            } else {
                end = Number(this.overlays[overlayKey][overlayAssetIndex].end);
            }


            if (start >= end) {
                this.unselect_word();
                this.clearOverlaysBracket();
                this.overlayStartBracketWordIndex = -1;
                this.changingOverlayBracketIn = -1;
                this.changingOverlayBracketOut = -1;
                this.currentOverlaySelectingIndex = -1;
                return;
            };

            this.overlays[overlayKey][overlayAssetIndex].start = start;
            this.overlays[overlayKey][overlayAssetIndex].end = end;

            /*

            this.overlays.assets[overlayAssetIndex].appearanceDuration = Number(this.overlays.assets[overlayAssetIndex].end - this.overlays.assets[overlayAssetIndex].start)
            
           
            this.putClipData({ _id: this.clip_id, overlays: this.overlays });
            this.$emit("reloadOverlays", this.overlays)
            */

            this.$emit("overlayTimingChanged", {
                overlayType,
                ...this.overlays[overlayKey][overlayAssetIndex]
            })
        },

        deleteOverlay() {
            let overlayKey;
            let overlayIndex;
            if (this.currentOverlaySelectingIndex != -1){
                overlayKey = "assets";
                overlayIndex = this.currentOverlaySelectingIndex;
            } else {
                overlayKey = "texts";
                overlayIndex = this.currentOverlayTextSelectingIndex;
            }
            this.overlays[overlayKey].splice(overlayIndex, 1);
    
            this.overlays[overlayKey].forEach((asset, idx) => {
                asset.asset_id = idx
            });

            this.unselect_word();
            this.clearOverlaysBracket();
            this.putClipData({ _id: this.clip_id, overlays: this.overlays });

            this.$emit("reloadOverlays", this.overlays)
        },

        changeOverlayPosition(newValue) {
            this.currentOverlayAssetSelected.position = newValue;
            this.unselect_word();
            this.clearOverlaysBracket();
            this.putClipData({ _id: this.clip_id, overlays: this.overlays });
            this.$emit("reloadOverlays", this.overlays)
        },

        editTextOverlay(){
            this.$emit('editOverlay', {
                overlayType : "text",
                ...this.overlays.texts[this.currentOverlayTextSelectingIndex]
            })
        }

    },
    beforeUnmount() {
        document.getElementById('project_timeline').removeEventListener('scroll', this.onScroll);
        document.getElementById('word_container').removeEventListener('scroll', this.onScroll);
    }
}
</script>

<style src="./style.scss" lang="scss" scopped></style>
