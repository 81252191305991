<template>
    <p class="credits">
        <img src="../../../../../../../../assets/sablier.svg" />
        {{ $t('ScriptPart.clip_type_1.remaining_credits') }} : {{ renderSeconds(subscriptionInfos.credits) }}
    </p>
</template>


<script>
import Vuex from "vuex"

//CUTSOMS LIBS
import {renderSeconds} from "../../../../../../../../lib/date-time-duration.js";
export default {
    computed: {
        ...Vuex.mapGetters([
            "subscriptionInfos",
        ])
    },
    methods : {
        renderSeconds
    }
}
</script>

<style lang="scss" scoped>


.credits{
    --border_radius : 12px;
    border-radius: var(--border_radius) var(--border_radius) var(--border_radius) var(--border_radius);
    background: linear-gradient(white, white) padding-box, linear-gradient(158deg, #3d3393, #2b76b9 31%, #2cacd1 57%, #35eb93) border-box;
    border: 4px solid transparent;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 10px;
    font-weight: 600 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        height: 18px;
        margin-right: 5px;
    }
}
</style>