<!-- CLIP TYPE 1 - RUSH RECORDED -->

<template>
  <div v-if="clip_data != undefined">

    <transition name="panel-slide">
      <OverlaysPanel ref="overlaysPanel" v-click-outside="closeOverlayPanel" panel-position="50"
        v-if="expanded && showOverlayPanel" :overlaytiming="temporaryOverlaytiming" :overlayToEdit="overlayToEdit"
        @overlaySelected="overlaySelected" @overlayModified="overlayModified" @mediaDeleted="mediaDeleted" @overlayTextSelected="addOverlayText"/>
    </transition>

    <!-- HEADER -->
    <div class="header center">
      <img src="../../../../../../assets/video_media.png" style="background-color: #f4b46a; " class="icon" />
      <div class="header_title">
        <p v-if="!expanded && clip_data.collapse_text != null">
          <i>{{ clip_data.collapse_text }}</i>
        </p>
        <p>{{ $t("ScriptPart.clip_type_1.title") }}</p>
      </div>
      <Switch v-if="expanded && media_id == null" leftChoice_display="Record" :leftChoice=false
        rightChoice_display="Upload" :rightChoice=true v-model="upload_mode" ref="switchText"
        class="recorder_upload_mode center" color="#f4b46a" height="25px" borderWidth="1.5px" borderRadius="8px"
        borderColor="#c1c1c1" />

      <span class="loading_spinner clip_status" style="--color: var(--color_font)"
        v-if="media_id != null && ready == false"></span>
      <img src="../../../../../../assets/checked_blue.svg" style="color: var(--color_font)" class="clip_status" v-else-if="ready == true" />
    </div>

    <!-- EXPANDED -->
    <div v-if="expanded" class="expanded_part" :class="'format_' + project_options.format">

      <!-- PLAYER -->
      <div v-if="media_id != null" class="player_container">
        <div class="player_ratio_container">
          <PlayerPicasso v-if="media_id != null" :clip_data="clip_data" :clip_id="clip_data?._id" :media_id="media_id"
            :background_color="background_color" ref="PlayerPicasso" @currentTimeChange="currentTimeChange"
            @ready="autoResizeTextarea(); piccasoIsReady = true" :optimization_progression="optimization_progression" :media="media"
            :key="project_options.audio_enhanced" @textOverlaySelected="textOverlaySelected" @textOverlayTransformed="textOverlayEdited"/>
        </div>
      </div>

      <!-- TEXT CONTAINER -->
      <div v-if="showTextContainer" class="text_container" :class="{ noVideo: (media_id == null) }">

        <!-- PROMPTER MODE / TRANSCRPTION -->
        <Switch v-if="media_id != null && !clip_data.imported" v-model="transcriptionMode" :leftChoice="false"
          :rightChoice="true" rightChoice_display="Transcription"
          :leftChoice_display="$t('ScriptPart.clip_type_1.prompter')" ref="switchText"
          class="switch_prompter_transcription" color="#f4b46a" height="25px" borderWidth="1.5px" borderRadius="8px"
          borderColor="#c1c1c1" :rightDisable="rightDisable" />

        <!-- PROMPTER -->
        <p v-if="media_id == null">{{ $t('ScriptPart.clip_type_1.prompter') }}</p>

        <template v-if="(!transcriptionMode || media_id == null)" :class="{ noVideo: (media_id == null) }">
          <textarea @keyup="complete_onboarding_task('fill_text_bloc'); autoResizeTextarea()"
            @blur="putClipData({ _id: clip_data._id, scrpt_text: clip_data.scrpt_text })" v-model="clip_data.scrpt_text"
            data-hj-allow class="textPrompter" :class="{ 'decay': !upload_mode && (media_id == null) }"></textarea>
          <div class="buttonRecord_container" v-if="!upload_mode && (media_id == null)">
            <ButtonRecord :recorder_link="recorder_link"></ButtonRecord>
          </div>
        </template>

        <!-- TEXT OVERLAY EDITOR -->
        <template v-if="textOverlay != null">
          <TextOverlayParametersEdit :textOverlay="textOverlay" @edited="textOverlayEdited"/>
        </template>

        <!-- TRANSCRPTION -->
        <template v-else-if="transcriptionMode && media_id != null && transcriptionAvailable">
          <Transcription ref="TranscriptionComponent" @reload_cuts="reload_cuts" @reloadOverlays="reloadOverlays" @seek="seek" @addOverlay="addOverlay"
            @editOverlay="modifyOverlay" @overlayTimingChanged="overlayModified" :media_id="media_id" :transcriptionData="transcriptionData"
            :currentTime="currentTime" :project_id="project_id" :clip_id="clip_data?._id || null"
            :overlays="clip_data.overlays" :picassoIsPlaying="picassoIsPlaying" :piccasoIsReady="piccasoIsReady"/>

        </template>

       

        <p class="words_info" :class="{ 'decay': !upload_mode && (media_id == null) }">
          <a v-if="!transcriptionMode">{{ countWords(clip_data.scrpt_text || "") }}
            {{
              $t("CreativeSpace.words", {
                plurial: countWords(clip_data.scrpt_text || "") > 1 ? "s" : "",
              })
            }}
          </a>
          <a v-if="!transcriptionMode && clip_data.estimated_duration"> |
            {{ displayDuration(clip_data.estimated_duration) }}</a>
          <a v-else-if="transcriptionMode && clip_data.duration">{{ displayDuration(clip_data.duration)
          }}</a>
        </p>
      </div>


      <!-- PAYEMENT FAILED => NO IMPORT -->
      <template v-if="upload_mode && media_id == null && subscriptionInfos.paymentFailed">
        <SubscriptionInfos class="upload_payementFailed" />
      </template>

      <!-- UPLOAD MODE -->
      <template v-else-if="upload_mode && media_id == null">

        <input ref="file_input" style="display: none;" type="file" accept="video/*" @change="input_file_select" />

        <!-- DROPZONE -->
        <div class="dropzone center" :class="{ 'drag_hover': hover_drop_zone }" ref="drop_zone"
          @drop="dropHandler($event)" @dragover="dragOverHandler" @dragleave="dragleaveHandler" draggable="true"
          @click="upload_click('video_record')">

          <!-- WAITING FOR UPLOADING FILE -->
          <div class="icon waiting_for_uploading_file">
            <img src="../../../../../../assets/upload_file_blue.png" />
            <img src="../../../../../../assets/upload_file_light_blue.png" />
          </div>
          <p class="waiting_for_uploading_file">{{ $t('ScriptPart.clip_type_1.upload_your_media') }}</p>
          <RemainingCredits v-if="file_name_uploading == null" style="transform: translateY(23px); margin-top: 0px;" />

          <!-- UPLOADING FILE -->
          <ProgressionCircle :pourcentage="upload_progress" class="progression_circle" />
          <p v-if="file_name_uploading != null && upload_progress == 0" class="uploading_file_name show">{{
            $t('ScriptPart.clip_type_1.loading_of') }} {{ file_name_uploading }} ...</p>
          <p v-else class="uploading_file_name">{{ $t('ScriptPart.clip_type_1.upload_of') }} {{ file_name_uploading }} ({{
            upload_progress }}%)</p>
        </div>

      </template>



    </div>
  </div>
</template>

<script>
//LIB
import axios from "axios";
import Vuex from "vuex";

//CUTSOMS LIBS
import PlayerPicasso from "../../../../../../components/Object/PlayerPicasso/PlayerPicasso.vue";
import { renderMs } from "../../../../../../lib/date-time-duration.js";
import countWords from "../../../../../../lib/countWords.js";

//COMPONENT
import ProgressionCircle from "../../../../../../components/Object/ProgressionCircle/ProgressionCircle.vue";
import Transcription from "./components/Transcription/Transcription.vue";
import ButtonRecord from "./components/ButtonRecord/ButtonRecord.vue";
import RemainingCredits from "./components/RemainingCredits/RemainingCredits.vue";
import SubscriptionInfos from "../../../../../../components/Object/SubscriptionInfos.vue";
import OverlaysPanel from "../../../../../../components/Panels/Overlays/Overlays.vue";
import TextOverlayParametersEdit from "./components/TextOverlayParametersEdit/TextOverlayParametersEdit.vue";

export default {
  inject: ['mediaLoader'],
  components: {
    ProgressionCircle,
    PlayerPicasso,
    Transcription,
    ButtonRecord,
    RemainingCredits,
    SubscriptionInfos,
    OverlaysPanel,
    TextOverlayParametersEdit
  },
  data() {
    return {
      project_id: this.$store.state.creativeSpace.project_data._id,
      hover_drop_zone: false,
      file_name_uploading: null,
      upload_progress: 0,
      previous_media: null,
      upload_mode: false,
      recorder_url: process.env.RECORDER_URL,
      apiBaseUrl: process.env.API_URL,
      media: null,

      currentTime: 0,
      mediaLoading: null,
      optimization_progression: null,
      transcriptionMode: true,
      upload_type: 'video_record',
      showOverlayPanel: false,
      temporaryOverlaytiming: null,

      piccasoIsReady : false,

      overlayToEdit: null,

      textOverlay : null
    }
  },
  props: [
    "index",
  ],
  mounted() {
    this.autoResizeTextarea()
    if (this.media_id == null && this.clip_data.imported) {
      this.upload_mode = true
    } else {
      this.upload_mode = false
    }

    if (this.media_id == null) {
      this.transcriptionMode = false
    } else {
      this.transcriptionMode = this.clip_data.imported
    }
  },
  watch: {
    async expanded(value) {
      if (value) {
        this.piccasoIsReady = false;
        this.textOverlay = null;

        if (this.media_id != null) {

          await this.init()
        }

        if (this.media_id == null && this.clip_data.imported) {

          this.upload_mode = true
        } else {
          this.upload_mode = false
        }
        this.$nextTick(() => {
          this.autoResizeTextarea()
        })
      } else {
        this.closeOverlayPanel();

        if (this.clip_data != undefined && this.clip_data != null && this.clip_data.subClips != undefined){
          delete this.clip_data.subClips// = undefined
        } 
        if (this.clip_data != undefined && this.clip_data != null && this.clip_data.transcription != undefined){
          delete this.clip_data.transcription// = undefined
        }
        if (this.clip_data != undefined && this.clip_data != null && this.clip_data.overlays != undefined) {
          delete this.clip_data.overlays// = undefined
        }
      }
    },
    transcriptionMode() {
      this.$nextTick(() => {
        this.autoResizeTextarea()
      })
    },
    media_id(value, oldValue) {
      if (value != null) {
        this.init();
      }
      if (value != null && oldValue == null) {
        this.refresh_user_info()
      }
    },
    textOverlay(){
      this.$nextTick(() => {
          this.autoResizeTextarea()
        })
    },
    "project_options.cut_level"(){
      this.piccasoIsReady = false
    }
  },
  computed: {
    ...Vuex.mapGetters([
      "subscriptionInfos",
      "getCurrentUser",
      "project_options"
    ]),
    showTextContainer() {
      if (this.upload_mode == true) {
        return false
      } else if (this.media_id != null && !this.ready) {
        return false
      } else if (!this.clip_data?.imported || this.transcriptionAvailable) {
        return true
      } else {
        return true
      }
    },
    transcriptionData() {
      if (this.clip_data == undefined && this.clip_data == null) {
        return null
      }

      return this.clip_data.transcription || null
    },
    transcriptionAvailable() {
      return this.transcriptionData != null && this.transcriptionData.length != 0
    },
    rightDisable() {
      if (this.transcriptionAvailable) {
        return undefined
      } else {
        return this.$t('unavailable')
      }
    },
    background_color() {
      if (this.$store.state.creativeSpace.project_data.identity != undefined) {
        return this.$store.state.creativeSpace.project_data.identity.ident_colors[0]
      } else {
        return undefined
      }

    },
    expanded() {
      return this.$store.getters['amIExpanded'](this.index)
    },
    clip_data: {
      get() {
        return this.$store.getters['clip_data'](this.index)
      },
      set(newValue) {
        this.$store.state.creativeSpace.project_data.project_timeline[this.index] = newValue
      }
    },
    media_id: {
      get() {
        return (this.clip_data?.media_id != undefined ? this.clip_data?.media_id : null)
      },
      set(value) {
        this.$store.state.creativeSpace.project_data.project_timeline[this.index].media_id = value
      }

    },
    ready() {
      return this.clip_data?.ready || false
    },
    ...Vuex.mapGetters(["getCurrentUsrId", "getCurrentUser"]),
    maximal_time() {
      if (this.getCurrentUser.usr_subscription?.name == "Unlimited") {
        return 60
      } else if (this.getCurrentUser.usr_subscription?.name == "Pro") {
        return 20
      } else {
        return 5
      }
    },
    recorder_link() {
      return process.env.RECORDER_URL +
        "/?project_id=" + this.project_id +
        "&clip_id=" + this.clip_data?._id +
        "&language=" + this.$i18n.locale
    },
    picassoIsPlaying(){
      if (this.$refs.PlayerPicasso != undefined && this.$refs.PlayerPicasso.isPlaying == true){
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...Vuex.mapActions(["fetchClipData", "putClipData", "complete_onboarding_task", "refresh_user_info", "updateCurrentUser"]),
    currentTimeChange(value) {
      this.currentTime = Number(value)
    },
    /* CLIP TYPE  1*/
    autoResizeTextarea() {
      
      $(".script_part.expanded").find("textarea").each(function () {
        $(this).parents(".script_part").css({
          "--textareaHeight": (this.scrollHeight) + "px",
        });
      });
      

      let height = $("#picasso_container").height()
      if (height != undefined && height != 0) {
        if (this.clip_data.imported != true) {
          height -= 40
        };

        $('.expanded_part #word_container, .textOverlayEditor').css("height", height + "px");
        $('.transcription_container').css("height", (height+40) + "px");
        
      }
    },
    countWords: countWords,
    displayDuration: renderMs,
    upload_click(type) {
      this.upload_type = type//"screen_record"
      this.$refs.file_input.click()
    },

    // MEDIA IMPORT
    async init() {
      console.log('init')
      //RESET VALUES
      this.mediaLoading = null
      this.optimization_progression = null
      this.media = null

      if (this.media_id == null && !this.clip_data.imported) {
        this.transcriptionMode = false;
        return;
      }

      //LOAD MEDIA
      if (this.mediaLoading != null) {
        try {
          this.mediaLoading.destroy()
        } catch (e) {
          console.log(e)
        }
      }

      this.mediaLoading = new this.mediaLoader('/medias/' + this.media_id + '/url')
      this.mediaLoading.on('progression', () => {
        this.optimization_progression = this.mediaLoading.optimization_progression
      })


      await new Promise((resolve) => {
        this.mediaLoading.on('media_ready', async () => {

          await this.fetchClipData(this.clip_data._id);

          resolve()
        })
        this.transcriptionMode = true
      })

      if (this.transcriptionData == null || this.transcriptionData.length == 0) {
        this.transcriptionMode = false
      }

      this.media = this.mediaLoading.media
      this.optimization_progression = null
      //RESTORE ELEMENT OF DROP ZONE 

      $(".progression_circle, .uploading_file_name").removeClass("show");
      this.file_name_uploading = null
      this.upload_progress = 0

      $(".waiting_for_uploading_file").removeClass("hide");
    },
    dropHandler(evt) {
      this.upload_type = 'video_record'
      evt.preventDefault();
      this.hover_drop_zone = false
      if (evt.dataTransfer && evt.dataTransfer.files[0]) {
        this.handelFile(evt.dataTransfer.files[0])
      }
    },
    dragOverHandler(evt) {
      evt.preventDefault();
      this.hover_drop_zone = true
    },
    dragleaveHandler() {
      this.hover_drop_zone = false
    },
    input_file_select(evt) {
      this.handelFile(evt.target.files[0])
    },
    async handelFile(file) {
      try {
        if (/video/.test(file.type) == false) {
          throw "E0403";
        }

        let formData = new FormData()
        formData.append('file', file)
        formData.append('usr_id', this.getCurrentUsrId)
        formData.append('media_type', this.upload_type)
        formData.append('imported', true)

        this.clip_data.ready = false
        this.uploading_progress_view()
        this.file_name_uploading = file.name

        if (file.size / 1024 / 1024 / 1024 > 5) {
          throw "E0401";
        }

        //UPLOAD
        await axios.post('/projects/' + this.project_id + '/timeline/' + this.clip_data._id + '/origin', formData,
          {
            //REPORT UPLOAD PROGRESS
            onUploadProgress: (async (progressEvent) => {
              this.upload_progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            })
          })
          .then(async (res) => {
            this.upload_mode = false
            if (res.data && res.data.media_id) {
              this.$store.commit("UPDATE_CLIP", res.data)
            }
          })
          .catch((err) => {
            throw err
          })

      } catch (err) {
        console.error(err?.response?.data || err)
        this.upload_mode = false
        if (err == "E0401" || err?.response?.data?.errorCode == "E0401") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooHeavy"));
        } else if (err?.response?.data?.errorCode == "E0402") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooLong", { time: this.maximal_time }));
        } else if (err == "E0403" || err?.response?.data?.errorCode == "E0403") {
          this.Toaster.warn(this.$t("CatalogPanel.error.videoFormatRequired"));
        } else if (err?.response?.data?.code == "notEnoughCredit" || err?.response?.data?.code == "missingCredit") {
          this.Toaster.prompt({
            title: this.$t('CatalogPanel.error.notEnoughCredit'),
            message: this.$t('CatalogPanel.error.doYouWantToUpgrade'),
            validate: this.$t('Upgrade'),
            cancel: this.$t('no'),
          }).then((response) => {
            if (response == "validate") {
              this.$router.push('/profile/subscriptions')
            }
          })
        }

        if (this.media_id != undefined) {
          this.upload_mode = false
        } else {
          this.init()
        }
      }
    },
    uploading_progress_view() {
      this.$nextTick(() => {
        // Hide element waiting for uploading file
        var i = 1;
        $(".waiting_for_uploading_file").each((index, element) => {
          setTimeout(() => {
            $(element).addClass("hide");
          }, 60 * i);

          i++;
        })

        //SHOW PRORGRESS CIRCLE
        this.$nextTick(() => {
          setTimeout(() => {
            $(".progression_circle, .uploading_file_name").addClass("show");
          }, 400)
        })
      })
    },
    async change_file() {
      this.previous_media = this.clip_data.media_id;

      //RESTORE ELEMENT OF DROP ZONE HIDDEN
      this.$refs.file_input.click()
    },

    /*---------TRANSCRIPTION-----------*/
    reload_cuts() {

      if (this.$refs.PlayerPicasso != null) {
        this.$refs.PlayerPicasso.load_cuts();
      }
    },
    reloadOverlays(params) {
      if (this.$refs.PlayerPicasso != null) {
        this.$refs.PlayerPicasso.initOverlays(params);
      }
    },
    seek(time) {
      if (this.$refs.PlayerPicasso.picasso == null) {
        return
      } else {
        this.$refs.PlayerPicasso.seekRealTime(time)
      }
    },

    /* ---- OVERLAYS --- */
    closeOverlayPanel() {
      if (this.showOverlayPanel == true) {
        this.showOverlayPanel = false
      }
    },
    addOverlay(data) {
      this.temporaryOverlaytiming = data;
      this.showOverlayPanel = true;
    },

    modifyOverlay(data) {

      if (data.overlayType == "asset"){
        this.overlayToEdit = data;
        this.showOverlayPanel = true;
      } else if (data.overlayType == "text"){
        this.$refs.PlayerPicasso.picasso.selectTextOverlayById(data.id)
      }
    },

    overlayModified(data) {

      this.temporaryOverlaytiming = null;
      this.showOverlayPanel = false;
      this.overlayToEdit = null;

      if (data == null) {
        return;
      }

      var overlayKey = (data.overlayType == "asset" ? "assets" : "texts");

      this.clip_data.overlays.assets = this.clip_data.overlays.assets.map(a => {return {...a, id : a.asset_id}})

      const currentOverlayIndex = this.clip_data.overlays[overlayKey].findIndex(a => a.id == data.id);
      this.clip_data.overlays[overlayKey][currentOverlayIndex].start = data.start;


      //Verify if overlay not overwrite antoher overlay (and change starting or ending time if necessary)
      ["assets", "texts"].forEach(key =>{
        this.clip_data.overlays[key].forEach(overlay => {
          if (key == overlayKey && overlay.id == this.clip_data.overlays[overlayKey][currentOverlayIndex].id) {
            return;
          }
  
          //If an overlay ends during the current selected overlay
          if (data.start < overlay.end && overlay.end <= data.end) {
            //Find last transcription element before selected overlay starts
            const lastTranscriptionElementBefore = this.transcriptionData.findLast(el => el.end <= data.start)
            overlay.end = lastTranscriptionElementBefore.end;
            overlay.appearanceDuration = overlay.end - overlay.start;
          }
  
          //If an overlay starts during the current selected overlay
          if (data.start < overlay.start && overlay.start < data.end) {
            //Find first transcription element after selected overlay starts
            const firstTranscriptionElAfter = this.transcriptionData.find(el => el.start >= data.end);
            overlay.start = firstTranscriptionElAfter.start;
            overlay.appearanceDuration = overlay.end - overlay.start;
          }
        })
      })
        
      //Verify duration max
      if (data.end > this.clip_data.subClips[this.clip_data.subClips.length - 1].end) {
        data.end = this.clip_data.subClips[this.clip_data.subClips.length - 1].end;
        data.appearanceDuration = data.end - data.start;
      }

      this.clip_data.overlays[overlayKey][currentOverlayIndex].end = data.end;
      if (data.appearanceDuration && data.overlayType == "asset"){
        this.clip_data.overlays[overlayKey][currentOverlayIndex].appearanceDuration = Number(data.appearanceDuration);
      }

      this.clip_data.overlays[overlayKey][currentOverlayIndex].trim_in = data.trim_in;
      
      //Remove reactivity
      let set = JSON.parse(JSON.stringify(this.clip_data.overlays));

      set.assets = set.assets.flatMap(a => { delete a.media_url; return a });
      set.texts = set.texts.flatMap(t => { delete t.object; return t });
      
      this.$refs.PlayerPicasso.initOverlays(set);

      //Refresh overlays in transcription
      this.$refs.TranscriptionComponent.renderTextSplitted();

      this.putClipData({ _id: this.clip_data._id, overlays: set })
    },

    overlaySelected(assetData) {

      //Init overlays keys
      if (this.clip_data.overlays == undefined) {
        this.clip_data.overlays = { "assets": [], "texts" : []}
      }

      this.clip_data.overlays.assets.push({
        asset_id: this.clip_data.overlays.assets.length,
        id: this.clip_data.overlays.assets.length,
        ...assetData,
        start: this.temporaryOverlaytiming.start,
        end: this.temporaryOverlaytiming.end
      });

      //Sorte index by start time
      this.clip_data.overlays.assets.sort(function (a, b) {
        return a.start - b.start;
      });

      this.clip_data.overlays.assets.forEach((asset, idx) => {
        asset.asset_id = idx;
        asset.id = idx
      });

      this.$refs.PlayerPicasso.initOverlays(this.clip_data.overlays)

      let set = JSON.parse(JSON.stringify(this.clip_data.overlays));
      set.assets = set.assets.flatMap(a => { delete a.media_url; return a });
      set.texts = set.texts.flatMap(t => { delete t.object; return t });
      

      this.putClipData({ _id: this.clip_data._id, overlays: set })

      this.showOverlayPanel = false;
      this.temporaryOverlaytiming = null;
      this.overlayToEdit = null;
    },

    addOverlayText(overlayData){
      if (this.clip_data.overlays.texts == undefined){
        this.clip_data.overlays.texts = []
      }

      let newTextOverlay = {
        ...overlayData,
        id: Math.max(...this.clip_data.overlays.texts.map(ovlT => ovlT.id)) + 3,
        start: this.temporaryOverlaytiming.start,
        end: this.temporaryOverlaytiming.end,
        params : [
          {
            label : "text",
            value : this.temporaryOverlaytiming.text || "Simple text"
          },
          ...(()=>{
            if (this.getCurrentUser.default_overlayText_params != undefined){
              const defaultParamsFound = this.getCurrentUser.default_overlayText_params.find(ovlT => ovlT.name == overlayData.name)
              if (defaultParamsFound != undefined){
                return defaultParamsFound.params
              }
            }
            return []
          })()
        ],
        x : 50,
        y : 50,
        scale : 100
      };

      this.clip_data.overlays.texts.push(newTextOverlay);

      
      let set = JSON.parse(JSON.stringify(this.clip_data.overlays));

      this.$refs.PlayerPicasso.initOverlays(set);

      set.assets = set.assets.flatMap(a => { delete a.media_url; return a });
      set.texts = set.texts.flatMap(t => { delete t.object; return t })

      this.putClipData({ _id: this.clip_data._id, overlays: set })

      this.showOverlayPanel = false;
      this.temporaryOverlaytiming = null;


    },

    async mediaDeleted() {
      //Force fetch clipData updates
      await this.putClipData({ _id: this.clip_data._id });
      this.$refs.PlayerPicasso.initOverlays(this.clip_data.overlays)
    },

    textOverlaySelected(textOverlay){
      this.textOverlay = textOverlay;
    },

    textOverlayEdited(data){

      if (this.clip_data.overlays.texts == undefined){
        this.clip_data.overlays.texts = []
      }

      //Find Overlay
      const textOverlayIndex = this.clip_data.overlays.texts.findIndex(textOverlay => textOverlay.id == data.textOverlayId)
      if (textOverlayIndex == -1){
        return;
      }

      if (data.param != undefined){
        const paramIndex = this.clip_data.overlays.texts[textOverlayIndex].params.findIndex(p => p.label == data.param.label);

        if (paramIndex != -1){
          this.clip_data.overlays.texts[textOverlayIndex].params[paramIndex].value = data.param.value
        } else {
          this.clip_data.overlays.texts[textOverlayIndex].params.push({
            "label" : data.param.label,
            "value" : data.param.value
          })
        }


        //Save text overlayParam as default params
        if (data.param.label != "text"){
          this.updateCurrentUser({
            "default_overlayText_param" : {
              "name" : this.clip_data.overlays.texts[textOverlayIndex].name,
              "params" : [{
                "label" : data.param.label,
                "value" : data.param.value
              }]
            }
          })
        }



      }

      if (data.x != undefined){
        this.clip_data.overlays.texts[textOverlayIndex].x = data.x
      }
      if (data.y != undefined){
        this.clip_data.overlays.texts[textOverlayIndex].y = data.y
      }
      if (data.scale != undefined){
        this.clip_data.overlays.texts[textOverlayIndex].scale = data.scale
      }



      this.putClipData({ _id: this.clip_data._id, overlays: this.clip_data.overlays });



    }
  }

}
</script>


<style lang="scss" src="./clip_type_1.scss" scoped></style>
<style lang="scss" src="./import.scss" scoped></style>

