<template>
    <div>
        <div
            :class="{
              undraggable: true,
              link_hover_zone_expander: true,
              linked: $parent.linked == true,
              hide : hide,
              
            }"
            @click="linkClip(position)"
          ></div>
          
          <div
            :class="{
              link: true,
              hide : hide,
              undraggable: true,
              linked: $parent.linked == true,
              script_part_hover : $parent.hover
            }"
            @click="linkClip(position)"
          >
            <svg
              class="link_ext_part"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 312.6 312.6"
              style="enable-background: new 0 0 312.6 312.6"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M76.8,148.8c0,0-7-7-27-27c-62-62,23-133,73-83c18,17,32,31,32,31"
              />
              <path
                class="st1"
                d="M244,154.9c0,0,7,7,27,27c62,62-23,133-73,83c-18-17-32-31-32-31"
              />
            </svg>
            <svg
              class="link_center_part"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 312.6 312.6"
              style="enable-background: new 0 0 312.6 312.6"
              xml:space="preserve"
            >
              <line class="st0" x1="105.8" y1="203.8" x2="204.9" y2="104.7" />
            </svg>

            <svg
              class="link_stroke"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <path class="st0" d="M2.5,4.5c0,180,59,226,138,226" />
            </svg>
            <svg
              class="link_strokeup"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <path class="st0" d="M2.5,4.5c0,180,59,226,138,226" />
            </svg>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
  methods: {
    ...Vuex.mapActions([
      'linkClip'
    ])
  },
  props: ["hide", "position"],  
}
</script>

<style src="./link.css" scoped>
</style>