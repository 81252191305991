<template>
    <div class="button" id="generate" :class="{'dropdownMode' : chooseLanguage, 'disabled' : disabled}" v-click-outside="collapse">
        <p class="generate" v-if="!waiting_for_api_response && !chooseLanguage" @click.stop="generate()"  v-tippy="{content:renderErrorMessage(this.$parent.verifBeforeGeneration)}">
            {{ $t("CreativeSpace.generate.button_action") }}
        </p>
        <span v-if="waiting_for_api_response" class="loading_spinner center"
          style="--color : white; position: relative"></span>
        <p v-if="!waiting_for_api_response && chooseLanguage" class="title" @click.stop="reset()">
            {{$t("CreativeSpace.generate.specify_lang_title")}} <img src="../../../../assets/arrow.svg" style="filter: invert(1)"/>
        </p>
        <div class="choices" v-if="!waiting_for_api_response && chooseLanguage">
            <p :class="{'show' : showChoice}" @click.stop="generate('fr-FR')"><img src="../../../../assets/languages_flags/fr.svg"/>{{$t('Languages.fr')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('en-US')"><img src="../../../../assets/languages_flags/en.svg"/>{{$t('Languages.en')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('es-ES')"><img src="../../../../assets/languages_flags/es.svg"/>{{$t('Languages.es')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('de-DE')"><img src="../../../../assets/languages_flags/de.svg"/>{{$t('Languages.de')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('it-IT')"><img src="../../../../assets/languages_flags/it.svg"/>{{$t('Languages.it')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('pl-PL')"><img src="../../../../assets/languages_flags/pl.svg"/>{{$t('Languages.pl')}}</p>
            <p :class="{'show' : showChoice}" @click.stop="generate('pt-BR')"><img src="../../../../assets/languages_flags/pt.svg"/>{{$t('Languages.pt')}}</p>
            
        </div>
    </div>
</template>

<script>
import { renderErrorMessage } from "../../verifBeforeGeneration.js"
import axios from "axios"
import Vuex from "vuex"
export default {
  props: [
    "disabled"
  ],
  data () {
    return {
        chooseLanguage : false,
        showChoice : false,
        waiting_for_api_response : false
    }
  },
  methods:{
    ...Vuex.mapActions(['complete_onboarding_task']),
    renderErrorMessage,
    displayLanguageSelector(){
        this.chooseLanguage = true
        setTimeout(()=>{
            this.showChoice = true
        }, 100)
    },
    reset(){
      this.chooseLanguage = false
      setTimeout(()=>{
          this.showChoice = false
      }, 100)
    },
    generate(project_language) {
      if (!this.$parent.verifBeforeGeneration.global) {
        
        if (this.$parent.autoSaveData.status == 1) {
          this.waiting_for_api_response = true
          let body = null
          if (project_language != undefined){
            body = { "project_language" : project_language}
          }
          axios
            .post(`/videos/generate`, body, {
              params: { project_id: this.$parent.project_id },
              timeout : 60000
            })
            .then(() => {
                //COMPLETE ONBOARDING TASK
                this.complete_onboarding_task('generate_video')

                this.waiting_for_api_response = false
                this.Toaster.success(this.$t("CreativeSpace.success.generation"));
                this.$router.push({
                    path: "/videos",
                });
            })
            .catch((err) => {
                this.waiting_for_api_response = false
                if (err?.response?.data?.errorCode == "E0201"){
                  this.Toaster.warn(this.$t("Toaster.warn.no_project_language_detected"));
                  this.displayLanguageSelector()
                } else if (err?.response?.data?.errorCode == "E0202"){
                  this.Toaster.warn(this.$t("Toaster.warn.all_medias_are_not_optimized"));
                } else if (err?.response?.data?.errorCode == "E0204"){
                  this.Toaster.warn(this.$t("Toaster.warn.noIdentity"));
                } else {
                    console.error(err.response?.data);
                    this.Toaster.error();
                }
            });
        } else {
          this.Toaster.warn(this.$t("CreativeSpace.warn.waitSave"));
        }
      }
    },
    collapse(){
      if (this.chooseLanguage){
        this.chooseLanguage = false
        this.waiting_for_api_response = false
      }
    }
  }

    
}
</script>

<style scoped src="./style.scss" lang="scss">

</style>