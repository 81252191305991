<template>
  <div :class="{ text_container: type != 'date', text_container_mode_text: type == 'text', editable: editable }"
    @click.stop="onFocus()">
    <autosaveinfo :data="autoSave" @save="change()" style="display: none" :saveDelay="1" />
    <input v-if="type == 'input'" type="text" ref="text" v-model="value" @blur="update" :placeholder="placeholder"
      autocomplete="off" data-lpignore="true" data-form-type="other" @keyup.enter="$refs.text.blur" @keyup.esc="cancel"
      class="editableText_input_mode" :id="id" data-hj-allow />

    <span ref="text" v-if="type == 'text' && displayedValue != ''" @blur="update" v-on:keypress.enter="update"
      class="editableText_text_mode" :id="id" :contenteditable="editable" @keyup.esc.stop="cancel" autocomplete="off"
      data-lpignore="true" data-form-type="other">{{ displayedValue }}</span>
  </div>
</template>

<script>
//COMPONENTS
import autosaveinfo from "./AutoSaveInfo";

//LIB
import { displayDate } from "../../../src/lib/date-time-duration.js";

export default {
  name: "editableText",
  components: { autosaveinfo },
  data: function () {
    return {
      date: Date,
      value: "",
      autoSave: {
        status: 1,
        lastModification: Date,
        lastSave: "",
      },
    };
  },
  emits: ["update:modelValue", "finished", "change"],
  props: {
    type: {
      required: false,
      default: "text",
    },
    modelValue: {
      required: true,
    },
    editable: {
      required: false,
      default: false
    },
    id: {
      required: false,
    },
    placeholder: {}
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value && value.replace(/\s/g, "") == "") {
          this.value = ""
        } else {
          this.value = value
        }
      },
      immediate: true
    },
    value() {
      this.autoSave.status = 0
      this.autoSave.lastModification = new Date()
    },
  },
  mounted() {
    //PREVENT LINE BREAK
    $(this.$refs.text).keypress(e => {
      if (e.which == 13) {
        this.update();
        $(this.$refs.text).blur()
        return false
      }
    });
  },
  computed: {
    displayedValue() {
      if (this.type == "text") {
        if (this.value != undefined && this.value != "") {
          return this.value;
        } else {

          return this.placeholder;
        }
      } else {
        return this.displayDate(this.date);
      }
    },
  },
  methods: {
    displayDate,
    onFocus() {
      if (this.editable) {

        if (this.type == "text") {
          $(this.$refs.text).focus()
        }

        let newValue = $(this.$refs.text).text()
        if (newValue == this.placeholder) {
          $(this.$refs.text).text("")
          this.$refs.text.style.color = "var(--color_font)"
        } else {
          this.$refs.text.style.color = null
        }
      }
    },
    update: function () {

      let newValue;
      if (this.type == "text") {
        this.$refs.text.style.color = null
        newValue = $(this.$refs.text).text()
      } else {
        newValue = this.value
      }

      if (newValue != this.placeholder) {
        this.value = newValue;
        this.$emit("update:modelValue", this.value);



      } else if (newValue == this.placeholder) {
        this.value = "";
        this.$emit("update:modelValue", this.value);
      }

      if (newValue == "") {
        if (this.type == "text") {
          $(this.$refs.text).text(this.placeholder)
        }
      }
    },
    change() {
      this.$emit('change', this.value)
    },
    cancel() {
      if (this.type == 'text') {
        this.$refs.text.innerHTML = this.modelValue
      } else if (this.type == 'input') {
        this.value = this.modelValue
      }
      this.$nextTick(() => {
        this.$refs.text.blur()
      })
    }
  }
};
</script>


<style scoped>
.text_container {
  width: 100%;
}

.editableText_text_mode {
  min-width: 10px;
  white-space: nowrap;
  cursor: default;
}

.text_container_mode_text.editable>.editableText_text_mode {
  cursor: text;
}

.text_container_mode_text {
  border: 1px solid transparent;
  padding: 2px 6px;
  transition: 200ms border, transform 0ms, padding 0ms;
  min-height: 22px;
  overflow-x: hidden;
  border-radius: 3px;
}

.text_container_mode_text.editable:hover {
  border: 1px solid var(--color_border);
  padding: 2px 6px !important;
}

.text_container_mode_text.editable:focus-within {
  border: 1px solid var(--color1);
  padding: 2px 6px !important;
}

.editableText_input_mode {
  width: 100%;
}
</style>
