<template>
    <div class="plr_ctrls_container">
        <img v-if="!playing" @click.stop="togglePlayPause" class="play_pause playCtrl-button"
            src="./assets/plyr-play.svg" />
        <img v-else @click.stop="togglePlayPause" class="play_pause playCtrl-button" src="./assets/plyr-pause.svg" />
        <input class="seek" type="range" @input="seek" v-model="currentTimePercent" min=0 max=100 step=0.1 ref="seek" />
        <p class="time">
            <a>{{ currentTimeRendered }}</a>
            <a> | </a>
            <a>{{ durationRendered }}</a>
        </p>
        <img v-if="muted" @click.stop="toggleMute" class="volume playCtrl-button" src="./assets/plyr-muted.svg" />
        <img v-else @click.stop="toggleMute" class="volume playCtrl-button" src="./assets/plyr-volume.svg" />
        <img v-if="isFullScreen" @click.stop="toggle_full_screen" class="fullscreen playCtrl-button"
            src="./assets/plyr-exit-fullscreen.svg" />
        <img v-else @click.stop="toggle_full_screen" class="fullscreen playCtrl-button"
            src="./assets/plyr-enter-fullscreen.svg" />
    </div>
</template>


<script>
import {renderMsShort} from "../../../../../lib/date-time-duration.js"

export default {
    data() {
        return {
            currentTimePercent: 0
        }
    },
    props: ["playing", "duration", "currentTime", "muted", "isFullScreen"],

    mounted() {
    },
    emits: ["seek", "play", "fullscreen", "mute", "toggleFullScreen"],
    watch: {
        currentTime(value) {
            this.currentTimePercent = (value * 100) / this.duration
        },
        currentTimePercent(percentValue) {
            this.$refs.seek.style.background = `linear-gradient(to right, var(--color1) ${percentValue}%, var(--slider_background) ${percentValue}%)`;
        }
    },
    methods: {
        seek(evt) {
            let seekingValue = ((evt.target.value * this.duration) / 100).toFixed(2)
            this.$emit('seek', seekingValue)
        },
        togglePlayPause() {
            this.$emit('play', !this.playing)
        },
        toggleMute(value) {
            this.$emit('mute', value)
        },
        toggle_full_screen() {
            this.$emit('toggleFullScreen', !this.isFullScreen)
        }
    },
    computed: {
        currentTimeRendered() {
            return renderMsShort(this.currentTime)
        },
        durationRendered() {
            return renderMsShort(this.duration)
        }
    }
}
</script>

<style src="./style.scss" lang="scss" scoped></style>