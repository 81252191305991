<template>
    <div style="position: relative;" class="textOverlayEditor">
        <div class="params">
            <template v-for="param in params.filter(p => p.type == 'text')" :key="param.label">
                <textarea type="text" class="param_text" v-model="param.value" @change="updateParams(param)"></textarea>
            </template>

            <div class="line_params">
                <div class="param_alignText">
                    <img src="../../../../../../../../assets/align-text-left.svg" :class="{'active' : param_alignText.value == 'left'}" @click.stop="updateParams({...param_alignText, value : 'left'})"/>
                    <img src="../../../../../../../../assets/align-text-center.svg" :class="{'active' : param_alignText.value == 'center'}" @click.stop="updateParams({...param_alignText, value : 'center'})"/>
                    <img src="../../../../../../../../assets/align-text-right.svg" :class="{'active' : param_alignText.value == 'right'}" @click.stop="updateParams({...param_alignText, value : 'right'})"/>
                </div>

                <div v-for="param in params.filter(p => p.type == 'color')" :key="param.label">
                    <ColorPicker class="colorPicker" v-model="param.value" :swatches="userColorSwatches" :enableAlpha="true" :enableInput="true" @update:modelValue="updateParams(param)"/>
                </div>
            </div>

            <div class="line_params">
                <v-select class="param_fontName" v-if="param_fontName != undefined" :options="picassoFonts" v-model="param_fontName.value" @update:modelValue="updateParams(param_fontName)" />
                <v-select class="param_animation" :options="param_animation_choices" :placeholder="t(`overlayText.simpleText_animation_choices.${param_animation.value}`)" @update:modelValue="$event => {updateParams({...param_animation, value : $event.value})}" />
                </div>
        </div>
    </div>

</template>

<script setup>
    import { defineProps, defineEmits, onMounted, computed } from 'vue';
    import Picasso from "@yopbox/picasso"

    const picassoFonts = Picasso.getAvailableFonts()

    import { useStore } from "vuex";
    const store = useStore();

    import * as VueI18n from "vue-i18n"
    const { t } = VueI18n.useI18n();
    //const Toaster = inject('Toaster');

    const emit = defineEmits(["edited"])

    const { textOverlay } = defineProps(["textOverlay"]);

    const params = computed(()=>{
        return textOverlay.params;
    });

    const param_alignText = computed(()=>{
        return textOverlay.params.find(p => p.label == "alignText")
    })

    const param_fontName = computed(()=>{
        return textOverlay.params.find(p => p.label == "fontName")
    })

    const param_animation = computed(()=>{
        return textOverlay.params.find(p => p.label == "animation");
    })

    const param_animation_choices = computed(()=>{
        if (param_animation.value != undefined){
            return param_animation.value.choices.flatMap(choice => {
                return {
                    "value" : choice,
                    "label" : t(`overlayText.simpleText_animation_choices.${choice}`)
                }
            })
        } else {
            return []
        }
    })



    const userColorSwatches = computed(()=>{
        return store.getters.getCurrentUser.colorSwatches
    })
    
    onMounted(()=>{
    });

    function updateParams(param){
        textOverlay.update(param);
        emit('edited', {
            textOverlayId : textOverlay._id,
            param
        })

    }
</script>

<style lang="scss" src="./style.scss" scoped></style>


<style>
.text_container{
    overflow: visible !important;
}
</style>