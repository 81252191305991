<template>
  <div class="selectDropdownRelative">
    <div class="selectDropdown">
        <div class="input_text_zone" @click="open = !open">
            <img class="arrow" :class="{'open' : open}" src="./arrow.svg" />
            <span v-if="choice == null">{{placeholder}}</span>
            <template v-else>
              <a>{{ choice.label }}</a>
              <img v-if="choice.img != undefined && choice.img != null" :src="require('@/assets'+choice.img)">
            </template>
            
        </div>
        <div class="options" :class="{'open':open}">
            <div class="option" v-for="(option, index) in options" :key="option.value" @click="optionSelected(index)">
                <a>{{ option.label }}</a>
                <img v-if="option.img != undefined && option.img != null" :src="require('@/assets'+option.img)">
            </div>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "SelectDropdown",
  props : ["options", "placeholder"],
  emits: ["select"],
  data(){
    return {
      open : false,
      choice : null
    }
  },
  methods :{
    optionSelected(index){
      this.choice = this.options[index];
      this.open = false,
      this.$emit('select', this.choice)
    },

  }

}
</script>


<style src="./style.scss" lang="sccs" scopped></style>