<template>
  <div panel-position="0" class="panel" id="catalogPannel">
    <!-- IDENTITY CHOICE/EDITION (TOP PART) -->
    <h1 class="title">{{ $t("CatalogPanel.choiceCharter") }}</h1>
    <div>
      <IdentityChoice :usr_id="getCurrentUsrId" :back_button_title="true" :hide_title="true" :isApanel="false"
        :isChoosable="true" :project_ids="project_id" :identity="identity || undefined"
        :template_identity="template_identity" @finished="chooseIdentity" :mode="mode" ref="identityChoice"
        @changeMode="identityModeChange" />
    </div>

    <template v-if="displayCatalog">
      <!-- ANIMATION CATALOGUE (BOTTOM PART) -->
      <h1 class="title">{{ $t("CatalogPanel.animationCatalog") }}</h1>

      <!-- YOPBOX ANIMATIONS -->
      <AnimationChoice ref="AnimationChoice" v-if="anim_type_filter != undefined" :anim_type_filter="anim_type_filter" :identity="identity"
        :format="format" @selectAnim="select">
      </AnimationChoice>

      <!-- PERSONNAL ANIMATION -->
      <h2 v-if="anim_type_filter == 0" class="center title">
        {{ $t("CatalogPanel.myAnims.intro") }}
      </h2>
      <h2 v-if="anim_type_filter == 1" class="center title">
        {{ $t("CatalogPanel.myAnims.title") }}
      </h2>
      <h2 v-if="anim_type_filter == 2" class="center title">
        {{ $t("CatalogPanel.myAnims.outro") }}
      </h2>
      <div id="personalAnimationsList" class="animations flexRow center">
        <!-- UPLOAD MEDIA AS ANIMATION CARD -->
        <div class="card" @click="$refs.uploadMediaAsAnimation.click()">
          <div class="uploadMedia">
            <img v-if="uploadProgress == 0" src="../../../assets/upload.png" />
            <p v-if="uploadProgress == 0">
              {{ $t("CatalogPanel.uploadMedia") }}
            </p>
            <span v-else-if="uploadProgress == 100" class="loading_spinner"
              style="--color:var(--color2);margin-top:10px;position:relative"></span>
            <p v-else>{{ uploadProgress }}%</p>

            <input type="file" ref="uploadMediaAsAnimation" hidden accept="video/*"
              @change="uploadMediaAsAnimation($event)" />
          </div>
        </div>
        <!-- USER MEDIAS -->
        <div class="card user_medias" :class="format" v-for="media in user_media_animation" :key="media._id"
          :ref="media._id" :id="media._id" @click="$emit('select', { media_id: media._id })">
          <!-- TRASH -->
          <span v-if="media.usr_id != undefined" class="trash"
            @click.stop="deleteMedia(media._id)" style="--trash_color : white; --trash_color_2 : transparent;"><span><i></i></span></span>

          <span class="template" v-else>Template</span>
          <div v-if="id_media_already_choose == media._id" class="used">
            <img src="../../../assets/checked_white.png" />
          </div>
          <div class="media_name">
            <p>{{ media.media_name }}</p>
          </div>
          <div class="media_thumbnail" :style="`--thumbnail_background_color : ${color_primary}`">
            <img :src="media.media_thumbnail" @error="cantDisplayMediaThumbnail(media._id)" />
          </div>
        </div>
      </div>


    </template>
    <div v-if="identityMode == 'choice'" class="flexRow">
      <p class="button center" button-type="secondary" style="margin-top: 30px" @click="finish()">
        {{ $t("Close") }}
      </p>
    </div>
  </div>
</template>

<script>
// LIBS
import emitter from "tiny-emitter/instance";
import axios from "axios";

// COMPONENT
import IdentityChoice from "../IdentityChoice/IdentityChoice.vue";
import AnimationChoice from "../AnimationChoice/AnimationChoice.vue"
//STORE
import Vuex from "vuex";

export default {
  name: "CatalogPanel",
  components: {
    IdentityChoice,
    AnimationChoice
  },
  props: [
    "default_feature_value",
    "anim_type_filter",
    "project_id",
    "identity",
    "id_media_already_choose",
    "template_values",
    "format",
    "mode"
  ],
  emits: ["select", "chooseIdentity"],
  data() {
    return {
      user_media_animation: [],
      uploadProgress: 0,
      identityMode: 'choice'

    };
  },
  mounted() {
    setTimeout(() => {
      emitter.emit("click_location", "AnimationCatalog");
    }, 500);

    if (this.anim_type_filter != undefined) {
      //FECTHING USER MEDIAS
      axios({
        methods: "get",
        url: `medias`,
        params: {
          usr_id: this.getCurrentUsrId,
          media_type: "animation",
        },
      })
        .then((res) => {
          let anim_medias_from_template = this.template_values?.anim_medias || []

          let user_medias = [
            ...res.data.filter((media) => media.anim_type == this.anim_type_filter), //USER MEDIAS
            ...anim_medias_from_template.filter((media) => media.anim_type == this.anim_type_filter) //MEDIAS FROM TEMPLATE
          ]

          //REMOVE MEDIAS WITH SAME ID
          const user_medias_cleaned = Array.from(new Set(user_medias.map(a => a._id))).map(id => {
            return user_medias.find(a => a._id === id)
          })

          //ADD THUMBNAIL
          this.user_media_animation = []
          user_medias_cleaned.forEach(async (media) => {
              if (media.media_thumbnail_path) {
                media.media_thumbnail = await axios({
                  methods: "get",
                  url: `storage/redirect/${media.media_thumbnail_path}`,
                })
                  .then((res) => {
                    return res.data;
                  })
                  .catch(() => {
                    return "";
                  });
              }

              this.user_media_animation.push(media);
            });

            
        })
        .catch((e) => {
          console.log(e);
        });
    }

  },


  computed: {
    ...Vuex.mapGetters(["getCurrentUsrId"]),
    default_feature_value_modified() {
      var set = this.default_feature_value;
      if (this.anim_type_filter != undefined && this.anim_type_filter == 2) {
        set.text = "www.yopbox.com";
      }

      return set;
    },
    template_identity() {
      if (this.template_values.identity != undefined) {
        return this.template_values.identity
      } else {
        return undefined
      }
    },
    displayCatalog() {
      if (this.anim_type_filter != undefined) {
        if (this.identityMode == 'choice') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    color_primary() {
      //background_color
      if (this.$store.getters['identity'].ident_colors != undefined) {
        return this.$store.getters['identity'].ident_colors[0]
      } else {
        return "#3d545c"
      }
    },
  },
  methods: {
    select(animation) {
      if (this.identity._id != undefined) {
        this.$emit("select", animation);
      } else {
        this.Toaster.warn(this.$t("CatalogPanel.warn.chooseCharter"));
      }
    },
    async deleteMedia(id) {
      try {
        //VERIFY LINK
        let links = await axios.get(`/medias/${id}/links`).then((res) => {return res.data})

        if (links.templates.length > 0){
          let message = this.$t('Toaster.media_used_template.message')
          links.templates.forEach(template =>{
            message += "\n  • " + template.timeline_template_name
          })
          this.Toaster.warn(message)
          return;
        };

        let media_used = false
        let message = this.$t('Toaster.media_used.message')
        if (links.projects.length > 0){
          media_used = true
          links.projects.forEach(project =>{
            message += "\n  • " + project.project_name
          })
        };

        if (!media_used){
          message = this.$t('Toaster.will_delete_media')
        }

        this.Toaster.prompt({
            title: this.$t('Toaster.areYouSure'),
            message,
            validate: this.$t('Delete'),
            cancel: this.$t('Cancel')
        }).then((response) => {
          if (response == "validate") {
            axios.delete(`/medias/` + id)
            .then(() => {
              let index = this.user_media_animation.findIndex(
                (anim) => anim._id == id
              );
              if (index != -1) {
                this.user_media_animation.splice(index, 1);
              }
              this.Toaster.success(this.$t('CatalogPanel.success.deleteAnim'))
              this.$emit('deleteAnim', id)
            })
            .catch(() => {
              this.Toaster.error(this.$t('CatalogPanel.error.deleteAnim'))
            })
          }
        })
      

      } catch (e){
        console.error(e)
      }
    },

    finish() {
      this.$emit("select", null);
    },
    chooseIdentity(identity) {

      this.$emit("chooseIdentity", identity);

      //RELOAD YOPBOX ANIMATIONS
      this.$refs.AnimationChoice.getAnimationCatalogue(identity._id)

    },
    cantDisplayMediaThumbnail(media_id) {
      document.getElementById(media_id).classList.value = document.getElementById(media_id).classList.value + " thumbnail_error"
    },
    uploadMediaAsAnimation(evt) {
      let form = new FormData();
      form.append("file", evt.target.files[0]);
      form.append("media_filename", evt.target.files[0].name);
      form.append("media_type", "animation");
      form.append("usr_id", this.getCurrentUsrId);
      form.append("anim_type", this.anim_type_filter);

      axios({
        method: "post",
        url: "/medias/",
        data: form,
        headers: {
          contentType: "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((res) => {
          this.Toaster.success(this.$t('ScriptPart.clip_media.media_uploaded'))
          this.uploadProgress = 0;
          this.$emit("select", {
            media_id: res.data._id,
          });
        })
        .catch((err) => {
          let errorMessage = this.$t("CatalogPanel.error.saveMedia");
          /*
          if (
            err.response &&
            err.response.data &&
            err.response.data == "File too heavy"
          ) {
            errorMessage = this.$t("CatalogPanel.error.mediaToHeavy");
          }
          if (
            err.response &&
            err.response.data &&
            err.response.data == "Animation media must be a video"
          ) {
            errorMessage = this.$t("CatalogPanel.error.mediaType");
          }
          */

          if (err?.response?.data?.errorCode == "E0401"){
            errorMessage = this.$t("CatalogPanel.error.mediaTooHeavy")
          } else if (err?.response?.data?.errorCode == "E0402"){
            errorMessage = this.$t("CatalogPanel.error.mediaTooLong")
          }

          this.uploadProgress = 0;
          this.Toaster.error(errorMessage);
        });
    },
    identityModeChange(mode) {
      this.identityMode = mode

    }


  },
};
</script>

<style src="./style.css" scoped></style>
<style src="./card_user_media.scss" scoped lang="scss"></style>
