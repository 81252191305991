<template>
    <div class="panel" panel_name="overlays">
        <!-- left menu -->
        <div class="left_menu">
            <span class="left_menu_selector" :class="{'selected' : library == 'textOverlays'}" @click.stop="changeLibrary('textOverlays')">Text</span>
            <span class="left_menu_selector" :class="{'selected' : library == 'userMedias'}" @click.stop="changeLibrary('userMedias')">{{$t('overlays.my_medias')}}</span>
            <span class="left_menu_selector" :class="{'selected' : library == 'pexels'}" @click.stop="changeLibrary('pexels')">Pexels</span>
            <span class="left_menu_selector" :class="{'selected' : library == 'giphy'}" @click.stop="changeLibrary('giphy')">Giphy</span>
        </div>

        <!-- right menu -->
        <div class="right_menu">
            <EditVideo v-if="mediaToEdit != null" :mediaToEdit="mediaToEdit" @finished="mediaEdited"/>

            <UserMedias v-else-if="library == 'userMedias' && mediaToEdit == null" @mediaSelected="selectMedia"/>
           
            <Giphy v-if="library == 'giphy' && mediaToEdit == null" @mediaSelected="selectMedia"/>

            <Pexels v-if="library == 'pexels' && mediaToEdit == null" @mediaSelected="selectMedia"/>

            <TextOverlays v-if="library == 'textOverlays' && mediaToEdit == null" @animationSelected="animationSelected"/>
        </div>
    </div>
</template>



<script setup>
//import upload from "./upload.vue"
import axios from "axios"
import { defineAsyncComponent, defineProps, defineEmits, ref, onMounted, inject } from 'vue';
import * as VueI18n from "vue-i18n"

const { t } = VueI18n.useI18n();
const Toaster = inject('Toaster');

const emit = defineEmits(['overlaySelected', 'overlayTextSelected', "overlayModified", "mediaDeleted"])

const { overlaytiming, overlayToEdit } = defineProps(["overlaytiming", "overlayToEdit"])

//const overlaytiming = defineProps("overlaytiming")
//const overlayToEdit = defineProps("overlayToEdit")

//COMPONENT
const UserMedias = defineAsyncComponent(() => import("./components/UserMedias.vue"));
const EditVideo = defineAsyncComponent(() => import("./components/editVideo/editVideo.vue"));
const Giphy = defineAsyncComponent(() => import("./components/Giphy.vue"));
const Pexels = defineAsyncComponent(() => import("./components/Pexels.vue"));
const TextOverlays = defineAsyncComponent(() => import("./components/texts/TextOverlays.vue"));



//DATAS
const library = ref(null);

onMounted(()=>{
    if (overlayToEdit != undefined && overlayToEdit != null){
        modifyMedia(overlayToEdit)
    } else {
        library.value = "textOverlays"
        //library.value = "pexels";
    }
});

function changeLibrary(libraryName){

    mediaToEdit.value = null
    library.value = libraryName;
}


async function modifyMedia(media){
    let set = media;
    
    if (set.media_url == undefined || set.media_url == null || set.media_duration == undefined || set.media_duration == null){
        if (set.media_id != undefined && set.media_id != null){
            const mediasFetched = await axios({
                methods: "get",
                url: `medias/${set.media_id}/url`,
            })
            .then((res) => {
                return {media_url : res.data.media_url, media_duration : res.data.media_duration}
            })
            .catch(() => {
                return null;
            });
    
    
            if (mediasFetched == null){
                Toaster.error();
                emit('overlayModified', null);
                return;
    
            }
    
            set.media_url = mediasFetched.media_url;
            set.media_duration = mediasFetched.media_duration;
        }
    }

    mediaToEdit.value = set;

}

async function selectMedia(media){

    let set = {
        position : "fit",
        media_duration : null,
        media_url : null,
        trim_in : null,
        start : Number(overlaytiming.start),
        end : Number(overlaytiming.end),
        type : media.type,
        media_id : media._id || null,
        static_url : media.static_url || null,
        origin : media.origin
    };
    

    if (set.origin == "user_media"){

        if (media.media_url == undefined && media.media_path != undefined){
            set.media_url = await axios({
                methods: "get",
                url: `medias/${set.media_id}/url`
            })
            .then((res) => {
                return res.data.media_url;
            })
            .catch(() => {
                return null;
            });
        }

    }

    set.media_duration = media.media_duration;

    set.appearanceDuration = overlaytiming.end - overlaytiming.start;

    if (set.appearanceDuration > media.media_duration){
        set.appearanceDuration = media.media_duration
    }


    if (media.type == "video"){
        set.trim_in = 0
    }

    set.width = media.media_width;
    set.height = media.media_height;

    //LAUNCH VIDEO EDIT
    if (set.type == "video" && set.origin != "giphy"){
        mediaToEdit.value = set;
    } else {
        emit('overlaySelected', set)
    }
}

const mediaToEdit = ref(null);

function mediaEdited(data){
    mediaToEdit.value = null;

    if (data == null){

        if (overlayToEdit != undefined && overlayToEdit != null){
            emit('overlayModified', null)
            return;
        } else {
            return;
        }
    }
    if (data.trim_in != null){
        data.trim_in = Number(data.trim_in);
    }
    
    if (data.start){
        data.start = Number(data.start);
    }
    
    if (data.end){
        data.end = Number(data.end);
    }

    data.overlayType = "asset"
    
    
    if (data.asset_id != undefined && data.asset_id != null){
        emit('overlayModified', data)
    } else {
        emit('overlaySelected', data)
    }

    mediaToEdit.value = null;
}

function animationSelected(animation){
    emit('overlayTextSelected', animation)
}


</script>


<style lang="scss" src="./overlays.scss"></style>