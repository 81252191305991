<template>
  <div class="clip_type_component">
    <!-- HEADER -->
    <div class="header center">
      <img v-if="clip_data.anim_type == 1" src="../../../../../../assets/title.svg"
        :style="`background-color: ${color_icon}`" class="icon" />

      <img v-else src="../../../../../../assets/animation.svg" :style="`background-color: ${color_icon}`" class="icon" />

      <div class="header_title">
        <p v-if="clip_data.media_id"> {{ media_name_fallback }}</p>
        <p v-else>{{ header_title }}</p>
      </div>

      <span v-if="clip_data.media_id != null && !clip_data.ready" class="loading_spinner clip_status" style="color:var(--font_color)"></span>
    
      <img v-else-if="clip_data.ready" src="../../../../../../assets/checked_blue.svg" style="color: var(--color2)" class="clip_status" />
      
    
    </div>

    <!-- EXPANDED INTRODUCTION ANIMATI0N -->
    <div class="expanded_part" v-if="clip_data != undefined &&
      clip_data.clip_type == 0 &&
      clip_data.anim_type == 0 &&
      (clip_data.media_id == undefined ||
        clip_data.media_id == null)
      ">
      <p></p>
      <animationViewer @click.stop="SHOW_CATALOG(true)" v-if="expanded" class="animation center undraggable"
        :animation="$store.state.creativeSpace.expanded_anim" :key="$store.state.creativeSpace.expanded_anim"
        :noAnimation="clip_data.anim_custom_id == null" :clickable="true" :format="project_options.format" />

    </div>

    <!-- EXPANDED TITLE ANIMATION-->
    <div class="expanded_part expanded_part_title" v-if="expanded == true &&
      clip_data != undefined &&
      clip_data.clip_type == 0 &&
      clip_data.anim_type == 1 &&
      (clip_data.media_id == undefined ||
        clip_data.media_id == null)
      ">

      <animationViewer @click.stop="SHOW_CATALOG(true)" v-if="expanded" class="animation center undraggable"
        :animation="$store.state.creativeSpace.expanded_anim" :key="$store.state.creativeSpace.expanded_anim"
        :noAnimation="clip_data.anim_custom_id == null" :clickable="true" :format="project_options.format" />

        <!--
      <editableText v-model="clip_data.title_content" type="input" :id="'input' + index"
        :placeholder="$t('ScriptPart.animation.titleAnimationPlaceHolder')" class="center undraggable title_content_input"
        @update:modelValue="putClipData({ _id: clip_data._id, title_content: clip_data.title_content })"
        @change="titleChange">
      </editableText>

      -->
      <textarea v-model="clip_data.title_content" :id="'input' + index" :placeholder="$t('ScriptPart.animation.titleAnimationPlaceHolder')" class="center undraggable title_content_input"
        @blur="putClipData({ _id: clip_data._id, title_content: clip_data.title_content })"
        @change="titleChange">
      </textarea>

    </div>

    <!-- EXPANDED OUTRO ANIMATION -->
    <div class="expanded_part" v-if="expanded &&
      clip_data != undefined && clip_data.anim_type == 2 &&
      (clip_data.media_id == undefined ||
        clip_data.media_id == null)
      ">
      <animationViewer @click.stop="SHOW_CATALOG(true)" v-if="expanded" class="animation center undraggable"
        :animation="$store.state.creativeSpace.expanded_anim" :key="$store.state.creativeSpace.expanded_anim"
        :noAnimation="clip_data.anim_custom_id == null" :clickable="true" :format="project_options.format" />
    </div>

    <!-- EXPANDED ANIMATI0N MEDIA -->
    <div class="expanded_part" v-if="expanded &&
      clip_data != undefined &&
      clip_data.media_id != null
      ">
      <div class="media center">
        <Player :autoplay="true" :loop="true" :muted="true" :controls="[]" :media_url="media_url"
          :format="project_options.format" :class="'center animation_' + project_options.format"
          :background_color="color_primary" @click.stop="SHOW_CATALOG(true)" />

      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import animationViewer from "../../../../../../components/Object/animationViewer";
//import Player from "../../../../../../components/Object/Player.vue";
import editableText from "../../../../../../components/Object/editableText.vue";

import Vuex from "vuex";

export default {
  data() {
    return {
      apiBaseUrl: process.env.API_URL,
      //video_src : null,
      media_url: null
    }
  },
  components: {
    //Player,
    animationViewer,
    editableText
  },

  props: [
    "index"
  ],
  watch: {
    expanded(val) {
      if (val == true) {
        this.init()

      }
    },
    media_id() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    ...Vuex.mapGetters(["getAnimCustomByPart"]),
    project_options() {
      return this.$store.state.creativeSpace.project_data.project_options
    },
    expanded() {
      return this.$store.getters['amIExpanded'](this.index)
    },
    clip_data() {
      return this.$store.getters['clip_data'](this.index)
    },
    media_id() {
      return this.clip_data?.media_id || null
    },

    header_title() {
      switch (this.clip_data?.anim_type) {
        case 0:
          return this.$t("ScriptPart.animation.intro")

        case 1:
          if (this.clip_data?.title_content != '') {
            return this.clip_data?.title_content
          } else {
            return this.$t("ScriptPart.animation.title")
          }

        case 2:
          return this.$t("ScriptPart.animation.outro")

        default:
          return ""
      }

    },
    icon() {
      switch (this.clip_data?.anim_type) {
        case 1:
          return "title.svg"

        default:
          return "animation.svg"
      }
    },
    color_icon() {
      switch (this.clip_data?.anim_type) {
        case 0:
          return "#44bce1"

        case 1:
          return "#44e3a9"

        case 2:
          return "#c298f5"
        default:
          return ""
      }
    },
    color_primary() {
      //background_color
      if (this.$store.getters['identity'].ident_colors != undefined) {
        return this.$store.getters['identity'].ident_colors[0]
      } else {
        return "#3d545c"
      }
    },
    media_name_fallback() {
      switch (this.clip_data?.anim_type) {

        case 0:
          return this.$t("ScriptPart.animation.intro")

        case 1:
          return this.$t("ScriptPart.animation.title")

        case 2:
          return this.$t("ScriptPart.animation.outro")
        default:
          return ""
      }
    }

  },
  methods: {
    ...Vuex.mapActions([
      "putClipData",
      "complete_onboarding_task"
    ]),
    ...Vuex.mapMutations(["SHOW_CATALOG"]),
    init() {
      if (this.media_id != null) {
        this.media_url = `${this.apiBaseUrl}/medias/${this.media_id}/url`
      }
    },
    titleChange(evt) {
      const text = evt.target.value
      
      //COMPLETE ONBOARDING TASK
      this.complete_onboarding_task("title_animation_edit")

      if (this.$store.state.creativeSpace.project_data.identity != undefined) {
        let ident_font = typeof this.$store.state.creativeSpace.project_data.identity.ident_font === 'object' ? this.$store.state.creativeSpace.project_data.identity.ident_font._id : this.$store.state.creativeSpace.project_data.identity.ident_font
        axios.post('/animations/build', { anim: { ...this.$store.state.creativeSpace.expanded_anim }, text, identity: { ident_font } })
          .then((res) => {
            //UPDATE ANIMATION VIEWING
            this.$store.commit('SET_EXPANDED_ANIM', { ...this.$store.state.creativeSpace.expanded_anim, ...res.data })
          })
      }
    },

  }
}
</script>

<style lang="scss" src="./script_part_animation.scss" scoped></style>

<style src="./script_part_animation_media.css" scoped></style>