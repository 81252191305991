<template>
  <div style="display: flex;" id="selectImg">
    <div v-for="object in data" :key="object" class="imgContainer"  @click="valueSelected=object.value"  :class="{ selected: valueSelected == object.value}">
      <img :src="require('@/assets'+object.srcImg)" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "selectImg",
  props: { data: {}, modelValue:{} },
  emits: ['update:modelValue'],
  data() {
    return {
    };
  },
  computed: {
    valueSelected: {
      get(){return this.modelValue},
      set(value){
        this.$emit("update:modelValue", value)
      }
    }
  },
  mounted() {

  },
  methods: {

  },
};
</script>
    
<style scoped lang="scss">
#selectImg{
  border: var(--color_border) solid 1px;
  border-radius: 5px;
  width: fit-content;
}

.imgContainer{
  padding: 9px;
  height: 30px;
  border-right: var(--color_border) solid 1px;
  border-left:none;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover{
    background-color: rgba(var(--color-toolbar), 0.2);
  }

  &:first-child{
    border-radius: 5px 0px 0px 5px;
    border-left:none;
  }

  &:last-child{
    border-radius: 0px 5px 5px 0px;
    border-right:none;
    border-left:none;
  }

  &.selected{
    background-color: rgba(var(--color-toolbar), 0.5) !important;
  }

  img {
    width : 30px;
    margin-top:auto;
    margin-bottom : auto;
  }
}





</style>
