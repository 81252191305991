const captions_templates = {
    "mr_beast" : {
        wordByPage : 3,
        backgroundColor : "#00000000",
        fontColor : "#FFFFFF",
        focusColor : "#F7F900",
        borderColor : "#000000",
        position : 30,
        fontSize : 40,
        borderSize : 5,
        wordAnimation : "wordByWord",
        fontName : "Bangers"
    },
    "minimal" : {
        wordByPage : 15,
        backgroundColor : "#00000069",
        fontColor : "#FFFFFFB8",
        focusColor : "#FFFFFF",
        borderColor : "#000000",
        position :15,
        fontSize : 20,
        borderSize : 0,
        wordAnimation : "Page",
        fontName : "Arial"
    },
    "dynamic" : {
        wordByPage : 9,
        backgroundColor : "#3eb1c8CF",
        fontColor : "#000000",
        focusColor : "#FFFFFF",
        borderColor : "#000000",
        position : 15,
        fontSize : 24,
        borderSize : 0,
        wordAnimation : "slideFromBotom",
        fontName : "Inter bold"
    }
}

export default captions_templates