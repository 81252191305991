<template>
  <div class="view_container">

    <!-- PANEL MUSIC -->
    <transition name="panel-slide">
      <MoodChoice v-click-outside="closeMoodPanel" v-if="panelMood" panel-position="0" class="panel"
        :project_id="project_id" :selected_moodId="moodId" @finished="panelMood = false" />
    </transition>

    <!-- PANEL ANIMATION CATALOGUE -->
    <transition name="panel-slide">
      <CatalogPanel v-click-outside="click_zone_to_close_half_panel_clicked" panel-position="50" class="identity_pannel"
        :back_button_title="true" v-if="isCatalogPanelVisible" :anim_type_filter="anim_type_filter"
        :project_id="project_id" :id_media_already_choose="id_media_already_choose" :template_values="template_values"
        @deleteAnim="deleteUserAnim" @select="chose_animation_from_catalog" @chooseIdentity="chooseIdentity"
        :identity="identity" :format="project_options.format" @click.stop="" :mode="identityMode" />
    </transition>

    <!-- HEADER BAR -->
    <div class="header">
      <div>
        <autosaveinfo :data="autoSaveData" :enableTrigger="false" class="autosaveinfo" />
        <editableText v-model="project_name" :class="{ titleEmpty: project_name == '' }" class="vdo_name" id="vdo_name"
          :editable="true" type="text" :placeholder="$t('IndicateTitle.project')" />
      </div>

      <h1 class="duration center" v-tippy="{ content: $t('CreativeSpace.estimatedTime') }">
        <!--{{ $t("CreativeSpace.estimatedTime") }}-->
        <img src="../../assets/chrono.svg" />
        <a class="timing">{{ displayDuration(project_duration) }}</a>
      </h1>


      <div>
        <div class="moodPart" @click.stop="panelMood = true"
          v-tippy="{ allowHTML: false, content: $t('CreativeSpace.tippyMood'), placement: 'left' }">
          <img v-if="moodId != null" src="../../assets/mood.png" class="icon" />
          <img v-else src="../../assets/add.svg" class="icon" />
          <p>{{ moodLabel }}</p>
        </div>

        <!-- BOUTON GENERATE -->
        <ButtonGenerate v-if="project_timeline != null && project_timeline.length != 0"
          :disabled="verifBeforeGeneration.global || autoSaveData.status != 1" />
      </div>
    </div>

    <!-- VERTICAL BANNER -->
    <transition name="fade" v-show="panelMood == true">
      <div class="banner_container" :class="{ 'active': panelMood == true }">
        <p class="vertical_banner">
          {{ $t("CreativeSpace.title") }}
        </p>
      </div>
    </transition>

    <!-- TOOLBAR -->
    <Toolbar />
    
    <!-- TIMELINE -->
    <div id="project_timeline">

      <draggable v-model="script_w_group_linked_parts" v-bind="dragOptions" @start="onDragStart" @end="onDragEnd"
        item-key="_id" id="draggable_handler">
        <template #item="{ element }">
          <div v-if="element.group == true" class="group">
            <Script_part v-for=" sub_element  in  element.list " :key="`sub_${sub_element.id}`"
              :position="sub_element.position" />
          </div>
          <div v-else>
            <Script_part :position="element.position" />
          </div>
        </template>
      </draggable>

    </div>
  </div>
</template>

<script>

// LIBS
import $ from "jquery";
import axios from "axios";
import draggable from "vuedraggable";

//LIBS CUSTOM
import { displayDate, renderMs } from "../../lib/date-time-duration"
import { verifBeforeGeneration } from "./verifBeforeGeneration"


//COMPONENTS
import autosaveinfo from "../../components/Object/AutoSaveInfo.vue";
import CatalogPanel from "../../components/Panels/CatalogPanel/CatalogPanel";
import editableText from "../../components/Object/editableText.vue";
import Script_part from "./components/Script_part/Script_part.vue";
import ButtonGenerate from "./components/ButtonGenerate/ButtonGenerate.vue"
import Toolbar from "./components/Toolbar/Toolbar.vue";

//PANELS
import MoodChoice from "../../components/Panels/MoodChoice/MoodChoice.vue";

//STORE
import Vuex from "vuex";
import store from "../../store/index";

export default {
  name: "Creative Space",
  components: {
    Script_part,
    autosaveinfo,
    CatalogPanel,
    editableText,
    MoodChoice,
    draggable,
    ButtonGenerate,
    Toolbar,
  },
  store: { store },
  data: function () {
    return {
      api_url: process.env.API_URL,
      project_id: null,
      panelMood: false,
      template_values: {},
      //indexToolbar: null,
      identityMode: null,
      //cutLevelValues: ["none", "low", "medium", "high"],
      //picassoFonts : picassoFonts,
      projectOptionsData: null,
      
    };
  },

  async mounted() {
    //LOAD PROJECT (STORE)
    this.project_id = this.$route.params.project_id;
    this.loadProject(this.project_id)
      .then(async (data) => {
        //COMPLETE ONBOARDING TASK
        this.complete_onboarding_task("open_project")

        //Expand clip_id in query
        if (this.$route.query.clip_id != undefined) {
          let clip_index = data.project_timeline.findIndex(clip => clip._id == this.$route.query.clip_id)
          this.$store.dispatch('iAmExpanded', clip_index)
        }

        if (data.project_timeline != undefined && data.project_timeline.length > 0) {
          //this.oldScript = JSON.parse(JSON.stringify(data.project_timeline)), (this.project_duration = data.project_duration || 0);
          this.oldScript = JSON.parse(JSON.stringify(data.project_timeline));
        }


        if (data.template_id != undefined) {
          await axios.get('/templates/' + data.template_id)
            .then((template) => {
              if (template.data.identity != undefined) {
                this.template_values.identity = template.data.identity
              }
              if (template.data.anim_medias != undefined) {
                this.template_values.anim_medias = template.data.anim_medias
              }
              if (template.data.mood_id != undefined) {
                this.template_values.mood_id = template.data.mood_id
              }
            })
            .catch(e => {
              console.log(e.response?.data)
              this.Toaster.warn(this.$t("Toaster.error.dataTemplateRecovery"));
            })
        }

        if (data.identity == undefined && this.template_values.identity != undefined) {
          this.identity = this.template_values.identity;
        } else {
          //this.identity = data.identity
        }

        this.projectOptionsData = JSON.parse(JSON.stringify(data.project_options))
        //Launch deamon refresh media id in clip
        this.refreshMedias()
      })
      .catch((err) => {
        console.error(err.response)
        if (err.response.status == 404) {
          this.Toaster.error(this.$t("Toaster.error.projectNotFound"));
          this.$router.push({ name: 'Home' })
        } else {
          this.Toaster.error(this.$t("Toaster.error.dataRecovery"));
        }

        setTimeout(() => {
          this.$emit("finished");
        }, 500);
      });


    //DETECT CLICK OUTSIDE SCRIPT PARTS TO COLLAPSE
    document.addEventListener("click", this.eventClickOusiteParts);

  },

  beforeUnmount() {
    this.SHOW_CATALOG(false)
    //document.removeEventListener("click", this.eventClickOusiteParts)
    this.$store.dispatch('unloadProject')
  },
  watch: {
    project_options: {
      handler(value) {
        this.projectOptionsData = JSON.parse(JSON.stringify(value))
      },

      deep: true
    }

  },
  computed: {
    ...Vuex.mapGetters(["project_options"]),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        handle: ".drag_handler"
      };
    },
    project_name: {
      // getter
      get() {
        return this.$store.state.creativeSpace.project_data.project_name
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        //this.$store.state.creativeSpace.project_data.project_name = newValue
        this.putProjectData({ "project_name": newValue })
      }
    },
    project_timeline: {
      get() {
        return this.$store.state.creativeSpace.project_data.project_timeline
      },
      set(value) {
        return this.$store.state.creativeSpace.project_data.project_timeline = value
      }
    },
    oldScript: {
      get() {
        return this.$store.state.creativeSpace.oldScript
      },
      set(value) {
        return this.$store.state.creativeSpace.oldScript = value
      }
    },
    project_duration() {
      return this.$store.state.creativeSpace.project_data.project_duration
    },
    script_w_group_linked_parts: {
      get() {
        //RETURN GROUPED TIMELINE
        return this.$store.getters['script_w_group_linked_parts']
      },
      set(value) {
        //UN GROUP TIMELINE
        return this.$store.dispatch('update_script_order_w_group_linked_parts', value)
      }
    },

    part_expanded() {
      const index_of_part_expanded = this.$store.state.creativeSpace.part_expanded
      return this.$store.getters['clip_data'](index_of_part_expanded)
    },
    id_media_already_choose() {
      if (this.part_expanded != undefined) {
        return this.part_expanded.media_id
      } else {
        return undefined
      }

    },
    anim_type_filter() {
      if (this.part_expanded != undefined) {
        return this.part_expanded.anim_type
      } else {
        return undefined
      }

    },
    project_options() {
      return this.$store.state.creativeSpace.project_data.project_options
    },
    
    autoSaveData() {
      return this.$store.state.creativeSpace.autoSave
    },
    ...Vuex.mapGetters([
      "identity",
      "isProjectLoaded",
      "moodId",
      "default_anims",
      "moodLabel",
      "getProjects",
      "timelineTemplates",
      "isAdmin",
      "isCatalogPanelVisible",
      "timelineTemplates",
      "isSubscribed",
      "getCurrentUser"
    ]),
    verifBeforeGeneration,
    watermark_bool(){
      if (this.project_options?.watermark == undefined || this.project_options?.watermark == null) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    displayDate,
    eventClickOusiteParts: function (evt) {
      var script_pannel_found = false;
      $(evt.target).parents().each((index, parent) => {
        if ($(parent).is(".script_part")) {
          script_pannel_found = true;
        }
        if ($(parent).is(".pcr-app.visible")) {
          script_pannel_found = true;
        }

      });

      //If click isn't on the script pannel collapse all script part
      if (script_pannel_found == false && this.isCatalogPanelVisible == false) {

        this.$store.commit('COLLAPSE_ALL_CLIPS')
        this.$store.commit('HIDE_ALL_ADDING_CLIP_DROPDOWS')
      }
    },
    onDragStart() {
      this.$store.commit('SET_DRAGGINIG_STATUS', true)
    },
    onDragEnd() {
      this.$store.commit('SET_DRAGGINIG_STATUS', false)

      //Force collapse all clips
      this.$store.commit('COLLAPSE_ALL_CLIPS')
    },

    ...Vuex.mapActions([
      "loadProject",
      "putProjectData",
      "putClipData",
      "refreshMedias",
      "complete_onboarding_task"
    ]),
    ...Vuex.mapMutations(['SHOW_CATALOG', "SAVING_MODE"]),
    displayDuration: renderMs,

    back: function () {
      this.$router.push({ path: "/" });
    },

    async chose_animation_from_catalog(animation) {
      this.identityMode = null
      const clip_id = this.part_expanded?._id;

      //IF ANIMATION IS A MEDIA
      if (animation?.media_id != undefined) {
        this.SAVING_MODE(true)

        //GET MEDIA INFO WITH PRESIGNED URL
        let mediaData = await axios.get(`medias/${animation.media_id}/url`).then(res => {
          return res.data
        })

        this.part_expanded.media_id = animation.media_id; //CHANGE LOCALLY
        this.part_expanded.transcoding = true
        this.part_expanded.transcoded = false
        this.part_expanded.media = mediaData
        this.part_expanded.ready = mediaData.ready


        //IF THERE WAS AN ANIMATION CUSTOM BEFORE => DELETE IT
        if (
          this.part_expanded.anim_custom_id != null &&
          this.part_expanded.anim_custom_id != undefined
        ) {
          axios({
            method: "delete",
            url: `/animations/${this.part_expanded.anim_custom_id
              }`,
          })
            .then(() => {
              console.debug("Custom animation deleted");
            })
            .catch(() => {
              console.error("Impossible to delete custom animation");
            });
          this.part_expanded.anim_custom_id = null; //CHANGE LOCALLY

        }

        this.SHOW_CATALOG(false)

        this.putClipData({
          "_id": clip_id,
          anim_custom_id: null,
          media_id: this.part_expanded.media_id
        })

      } else if (animation != null) {
        //COMPLETE ONBOARDING TASK
        this.complete_onboarding_task("select_animation")

        this.SAVING_MODE(true)

        this.$store.commit('SET_EXPANDED_ANIM', undefined)


        //SAVE DEFAULT ANIM
        if (animation.anim_type == 0
          && (this.default_anims.intro == undefined
            || this.default_anims.intro == null)) {
          this.$store.state.creativeSpace.project_data.default_anims.intro = animation.animGrp_id
        }
        else if (animation.anim_type == 1
          && (this.default_anims.title == undefined
            || this.default_anims.title == null)) {
          this.$store.state.creativeSpace.project_data.default_anims.title = animation.animGrp_id
        }
        else if (animation.anim_type == 2
          && (this.default_anims.outro == undefined
            || this.default_anims.outro == null)) {
          this.$store.state.creativeSpace.project_data.default_anims.outro = animation.animGrp_id
        }

        //CLOSE PANEL
        this.SHOW_CATALOG(false)

        //UPDATE ANIMATION ALREADY EXIST
        if (this.part_expanded.anim_custom_id != null) {
          axios.put('/animations/' + this.part_expanded.anim_custom_id, {
            ident_id: this.identity._id,
            animation_id: animation._id,
            text: this.part_expanded.anim_type == 1 ? this.part_expanded.title_content : undefined
          })
            .then((res) => {
              this.$store.commit('SET_EXPANDED_ANIM', res.data)
              this.SAVING_MODE(false)

            })
            .catch((e) => {
              console.error(e)
              window.Toaster.error(this.$t("Toaster.error.update"));
              this.SHOW_CATALOG(false)
            })
          //CREATE A NEW ANIMATION
        } else {
          this.SAVING_MODE(true)

          this.$store.state.creativeSpace.project_data.project_timeline.forEach((clip, idx) => {

            //FOR EACH SAME ANIMATION TYPE OF PROJECT..
            if (clip.anim_type != undefined && clip.anim_type == this.part_expanded.anim_type) {

              if (this.$store.state.creativeSpace.clip == idx) {
                this.$store.commit("SET_PART_TIMELINE", { index: idx, set: { anim_custom_id: -1, media_id: null, ready: false } })
              }

              axios.post('/animations/', {
                ident_id: this.identity._id,
                animation_id: animation._id,
                text: clip.anim_type == 1 ? clip.title_content : undefined
              })
                .then((res) => {

                  this.putClipData({
                    "_id": clip._id,
                    "anim_custom_id": res.data._id,
                    "media_id": null
                  })

                  this.$store.commit('SET_EXPANDED_ANIM', res.data)

                  this.SHOW_CATALOG(false)
                })
                .catch(() => {
                  this.$store.commit("SET_PART_TIMELINE", { index: idx, set: { anim_custom_id: null } })
                  this.SHOW_CATALOG(true)
                })
            }

          });

        }
      } else {

        this.SHOW_CATALOG(false)
      }
    },
    chooseIdentity(identity) {

      //SET NEW IDENTITY ID IN PROJECT DATA
      this.putProjectData({
        ident_id: identity._id,
        identity
      }).catch()

      if (identity != undefined) {

        this.project_timeline.forEach((part, index) => {  // refresh all animation custom of project with new identity selected
          if (part.anim_custom_id != null) {
            if (this.$store.state.creativeSpace.part_expanded == index) {
              this.$store.commit('SET_EXPANDED_ANIM', undefined)
            }
            let text = part.anim_type == 1 ? part.title_content : undefined
            axios.put('/animations/' + part.anim_custom_id, {
              ident_id: identity._id,
              text
            })
              .then((res) => {
                if (this.$store.state.creativeSpace.part_expanded == index) {
                  this.$store.commit('SET_EXPANDED_ANIM', res.data)
                }
              })
          }
        });
      }

    },
    deleteUserAnim(id) {
      this.project_timeline.forEach((part) => {
        if (part.media_id != undefined && part.media_id == id) {
          part.media_id = undefined;
        }
      });
    },
    click_zone_to_close_half_panel_clicked() {
      this.identityMode = null
      this.SHOW_CATALOG(false)
    },
    closeMoodPanel() {
      this.panelMood = false
    },
    
    
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
<style lang="scss" src="./header.scss" scoped></style>
<style scoped>
iframe {
  border-radius: 10px;
}

.ghost {
  opacity: 0;
}

.drag {
  opacity: 1;
}
</style>