<template>
    <div>
        <!-- YOPBOX ANIMATIONS -->
        <h2 v-if="anim_type_filter == 0" class="center title">
            {{ $t("CatalogPanel.customAnims.intro") }}
        </h2>
        <h2 v-if="anim_type_filter == 1" class="center title">
            {{ $t("CatalogPanel.customAnims.title") }}
        </h2>
        <h2 v-if="anim_type_filter == 2" class="center title">
            {{ $t("CatalogPanel.customAnims.outro") }}
        </h2>
        <h2 v-if="anim_type_filter == undefined" class="center title">
            Animations
        </h2>
        <div class="animations flexRow center" id="yopboxAnimationsList">
            <animationViewer class="card" v-for="animation in animations" :key="animation" @click="select(animation)"
                :animation="animation" :autoplay="false" :format="format" />
        </div>
    </div>
</template>

<script>
// LIB
import axios from "axios"
// COMPONENT
import animationViewer from "../../Object/animationViewer.vue";
export default {
    props: ["anim_type_filter", "identity", "format"],
    emits: ["selectAnim"],
    data() {
        return {
            animations: [undefined]
        };
    },
    components: { animationViewer },
    mounted() {
        if (this.identity != undefined) { // get list of animationCatalog with axios request to API 
            this.getAnimationCatalogue(this.identity._id)
        } else {
            this.getAnimationCatalogue()
        }
    },
    watch: {
        identity(identity) {
            this.getAnimationCatalogue(identity._id)
        }
    },
    methods: {
        getAnimationCatalogue(ident_id) { // get list of animationCatalog with axios request to API 
            for (let idx = 0; idx < this.animations.length; idx++) {
                this.animations[idx] = undefined
            }
            let url_animations = '/animations' // add anim_type to query to filter recuperation directly in api
            if (this.anim_type_filter != undefined) {
                url_animations += '?anim_type=' + this.anim_type_filter
            }
            if (ident_id != undefined) {
                url_animations += '&ident_id=' + ident_id // add ident_id selected, if not given, retrieve animation as they are by default in db 
            }
            if (this.anim_type_filter == 1 && this.identity != undefined) {
                url_animations += '&text=' + this.$t('CreativeSpace.exampleQuestion')
            }
            if (this.format != undefined) {
                url_animations += '&format=' + this.format
            }
            return axios.get(url_animations)
            .then((res) => {
                this.animations = res.data
            })
        },
        select(animation) {

            this.$emit("selectAnim", animation);
        }
    }


}
</script>

<style src="../CatalogPanel/style.css" scoped></style>