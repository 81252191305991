<!-- eslint-disable no-undef -->

<template>
  <div class="script_part smooth_margin_bottom center" style="" :class="{
    expand_margin_bottom_for_plus_button: !linked && !dragging && hover && !add_clip_dropdown_displayed,
    expand_margin_bottom_for_adding_pannel: add_clip_dropdown_displayed,
    expanded: expanded,
    linked: linked,
    last: isTheLastPart,
    text: clip_data != undefined && clip_data.clip_type == 1,
  }" :index="position" @mouseenter="hover = true" @mouseleave="hover = false">

  


    <!-- MOUSE DOWN CLICK DETECTION ZONE -->
    <div class="mouse_down_detection_zone drag_handler" @mousedown="hover == true ? hover = false : ''" @click="expand"
      :index="position"></div>


    <!-- TRASH -->
    <span class="trash_hover_zone_expander" @click="$store.commit('COLLAPSE_ALL_CLIPS')"></span>
    <span class="trash" :class="{ hide: linkedWithPrevious }" @click.stop="deletePart()"><span></span><i></i></span>


    <!-- LINK -->
    <Link :hide="dragging || add_clip_dropdown_displayed || isTheLastPart" :position="position" />

    <!-- ADD PART BUTTON -->
    <div class="addpart_hover_zone_expander center">
      <img src="../../../../assets/plus-circle.svg" class="plus_circle_button center"
        @click.stop="launch_add_clip_dropdown()" />
    </div>

    <!-- ADD PART-->
    <div class="addpart_hover_zone_expander center">

      <div class="add_part_to_script_pannel flexRow" :ref="`add_script_part_pannel_${position}`"
        v-if="add_clip_dropdown_displayed" v-click-outside="close">
        <div class="choice" @click="addClip(position + 1, 'intro')" style="--background_color: 68, 188, 225">
          <img src="../../../../assets/animation.svg" class="icon" />
          <p>{{ $t("ScriptPart.animation.intro") }}</p>
        </div>

        <div class="choice" @click="addClip(position + 1, 'title')" style="--background_color: 68, 227, 169">
          <img src="../../../../assets/title.svg" class="icon" />
          <p>{{ $t("ScriptPart.animation.title") }}</p>
        </div>
        <div class="choice double" style="--background_color: 68, 227, 169"
          @click="addClip(position + 1, 'titleAndMedia')">
          <img src="../../../../assets/title.svg" class="icon" />
          <p>{{ $t("ScriptPart.animation.title") }}</p>
          <p>+</p>
          <img src="../../../../assets/video_media.png" class="icon" style="--background_color: 244, 180, 106" />
          <p>{{ $t("ScriptPart.clip_type_1.title") }}</p>
        </div>
        <div class="choice" @click="addClip(position + 1, 'media')" style="--background_color: 244, 180, 106">
          <img src="../../../../assets/video_media.png" class="icon" />
          <p>{{ $t("ScriptPart.clip_type_1.title") }}</p>
        </div>
        <div class="choice" @click="addClip(position + 1, 'outro')" style="--background_color: 194, 153, 245">
          <img src="../../../../assets/animation.svg" class="icon" />
          <p>{{ $t("ScriptPart.animation.outro") }}</p>
        </div>
      </div>
    </div>


    <!-- CONTENT -->
    <Clip_type_0 v-if="clip_data?.clip_type == 0" :index="position" />
    <Clip_type_1 v-if="clip_data?.clip_type == 1" :index="position" :clip_type="clip_data?.clip_type" />

  </div>
</template>

<script>
import Link from "./components/link/Link.vue"
import Clip_type_0 from "./components/clip_type_0/Clip_type_0.vue"

import Clip_type_1 from "./components/clip_type_1/Clip_type_1.vue"

//LIB
import countWords from "../../../../lib/countWords.js"

import Vuex from "vuex";

export default {
  components: {
    Link,
    Clip_type_0,
    Clip_type_1
  },
  data() {
    return {
      api_url: this.$parent.api_url,
      hover: false,
      identity: this.$parent.identity
    }
  },
  props: ["position"],
  computed: {
    linked() {
      return this.clip_data != undefined ? this.clip_data.linked || false : false
    },
    linkedWithPrevious() {
      return this.$store.getters['linkedWithPrevious'](this.position)
    },
    expanded() {
      return this.$store.getters['amIExpanded'](this.position)
    },
    clip_data() {
      return this.$store.getters['clip_data'](this.position)
    },
    isTheLastPart() {
      return this.$store.getters['isTheLastPart'](this.position)
    },
    add_clip_dropdown_displayed() {
      return this.$store.getters['add_clip_dropdown_displayed'](this.position)
    },
    ...Vuex.mapGetters([
      "dragging"
    ])
  },
  methods: {
    countWords: countWords,
    close() {
      this.$store.state.creativeSpace.add_clip_dropdown_displayed = -1
    },
    addClip(index, type) {
      this.$store.dispatch('add_clip', { index, type })
    },
    deletePart() {
      this.$store.dispatch('deleteClip', this.position)
        .catch(e => {
          console.error("Unable to delete clip :", e)
        })
    },
    launch_add_clip_dropdown() {
      if (this.$store.state.creativeSpace.add_clip_dropdown_displayed == -1) {
        this.$store.commit('SHOW_ADDING_CLIP_DROPDOWN', this.position)
        //Animate the appearance of choice
        this.$nextTick(() => {
          var i = 1;
          $(`.script_part:eq(${this.position}) > .addpart_hover_zone_expander > .add_part_to_script_pannel > div`).each((index, element) => {
            setTimeout(() => {
              $(element).addClass("show");
            }, 60 * i);
            i++;
          });
        });
      } else {
        this.close()
      }
    },

    async expand() {

      this.$store.dispatch('iAmExpanded', this.position)
    }
  },
}
</script>

<style></style>


<style lang="scss" src="./add_part.scss"></style>

<style lang="scss" src="./script_part.scss"></style>

<style src="./trash.css"></style>