import i18n from '../../i18n'
const I18n = i18n.global

function renderErrorMessage(errorsData) {
  try {
    if(!errorsData.global){
      return ""
    }
    
    let message = I18n.t("CreativeSpace.warn.generate.elemMissing");

    if (errorsData.project_title) {
      message += I18n.t("CreativeSpace.warn.generate.title");
    }


    if (errorsData.rush.length != 0) {
      message += I18n.t("CreativeSpace.warn.generate.record", {
        number: errorsData.rush.length,
        plurial: errorsData.rush.length > 1 ? "s" : "",
      });
    }

    if (errorsData.clip_media.length != 0) {

      message += I18n.t("CreativeSpace.warn.generate.media", {
        number: errorsData.clip_media.length,
        plurial: errorsData.clip_media.length > 1 ? "s" : "",
      });

    }

    
    if (errorsData.optimization.length > 0) {

      message += I18n.t("CreativeSpace.warn.generate.optimization", {
        number: errorsData.optimization.length,
        plurial: errorsData.optimization.length > 1 ? "s" : "",
      });

    }

    
    if (errorsData.mood) {
      message += I18n.t("CreativeSpace.warn.generate.mood");
    }
    if (errorsData.identity) {
      message += I18n.t("CreativeSpace.warn.generate.charter");
    }

    if (errorsData.watermark) {
      message += I18n.t("CreativeSpace.warn.generate.watermark");
    }

    if (
      errorsData.intro.length != 0 ||
      errorsData.title.length != 0 ||
      errorsData.outro.length != 0
    ) {
      message +=  "<br>"+I18n.t("CreativeSpace.warn.generate.incompleteAnim", {
        number: errorsData.intro.length +
          errorsData.title.length +
          errorsData.outro.length,
        plurial: errorsData.intro.length +
          errorsData.title.length +
          errorsData.outro.length >
          1
          ? "s"
          : "",
      });

      if (errorsData.intro.length != 0) {
        message += `  • ${errorsData.intro.length} intro${errorsData.intro.length > 1 ? "s" : ""
          } <br>`;
      }
      if (errorsData.title.length != 0) {
        message += I18n.t("CreativeSpace.warn.generate.titleAnim", {
          number: errorsData.title.length,
          plurial: errorsData.title.length > 1 ? "s" : "",
        });
      }
      if (errorsData.outro.length != 0) {
        message += `  • ${errorsData.outro.length} outro${errorsData.outro.length > 1 ? "s" : ""} <br>`;
      }

    }
   // message += '<br>'
    return message
  } catch {
    return undefined
  }

}

function verifBeforeGeneration() {
  let errors = {
    intro: [],
    outro: [],
    title: [],
    rush: [],
    clip_media: [],
    optimization: [],
    project_title: false,
    mood: false,
    identity: false,
    watermark: false,
    global: false,
  };

  if (this.project_name == "") {
    errors.project_title = true;
    errors.global = true;
  }

  this.project_timeline.forEach((clip, index) => {
    switch (clip.clip_type) {
      case 0:
        if ((clip.anim_custom_id == undefined || clip.anim_custom_id == null) && (clip.media_id == undefined || clip.media_id == null)) {
          errors.global = true;

          if (clip.anim_type == 0) {
            errors.intro.push(index);
          }
          if (clip.anim_type == 1) {
            errors.title.push(index);
          }
          if (clip.anim_type == 2) {
            errors.outro.push(index);
          }
        } else if (clip.media_id != null) {
          if (clip.media?.ready == false) {
            errors.global = true;
            errors.optimization.push(index);
          }
        }
        break;

      case 1:

        if (clip.media_id == undefined && clip.media_id == null) {
          errors.global = true;
          errors.rush.push(index);
        } else if (clip.ready == false) {
          errors.global = true;
          errors.optimization.push(index);
        }
        break;

    }


  });


  if (this.moodId == null) {
    errors.global = true;
    errors.mood = true;
  }

  //IF CLIP ANIMATION IS PRESENTS, IDENTITY MUST BE SETTING UP
  let thereIsClipType0 = this.project_timeline.filter(c => {c.clip_type == 0}).length > 0

  if (thereIsClipType0 == true && this.identity._id == undefined) {
    errors.global = true;
    errors.identity = true;
  } else if (this.identity?.ident_watermark == null && this.watermark_bool == true) {
    errors.global = true;
    errors.watermark = true;
  }

  return errors;
}

export { renderErrorMessage, verifBeforeGeneration }
 