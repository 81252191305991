export default function(scrpt_text) {
    if (scrpt_text != "") {
      var wordCount = scrpt_text.split(/\S+/g).length - 1 || 0;
      if (wordCount == -1) {
        wordCount = 0;
      }
      return wordCount;
    } else {
      return 0;
    }
}