<template>
    <div class="buttonRecord">

        <div class="record" @click="openRecorder()" v-tippy="$t('ScriptPart.clip_type_1.go_to_recorder')">
            <img src="../../../../../../../../assets/camera.svg" />
        </div>

        <span></span>
        <div class="link" @click="copyLinktoClipBoard($event)" v-tippy="{content : $t('ScriptPart.clip_type_1.recorder_link'), placement: 'right',}">
            <img src="../../../../../../../../assets/link.svg" />
        </div>
        <span></span>

        <div v-if="QRcodeSrc != null" class="qrCode valide" v-tippy="{placement : 'bottom', content: `<img style='${QrCodeStyle}' src='${QRcodeSrc}'/>`}"
            @click="copyLinktoClipBoard($event)">
            <img src="../../../../../../../../assets/qrcode.svg" />
        </div>
        <div v-else class="qrCode valide" @click="copyLinktoClipBoard($event)">
            <img src="../../../../../../../../assets/qrcode.svg" />
        </div>

        

        <a class="recordLink">{{ recorder_link }}</a>
    </div>
</template>

<script>
import QRCode from "qrcode";
import { mapActions } from "vuex";

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
    props: ['recorder_link'],
    data() {
        return {
            QRcodeSrc: null,
            QrCodeStyle: "height : 100px; padding:5px"
        }
    },
    mounted() {
        //WAIT FOR RECORDING LINK AVAILABLE TO GENERATE QRCODE

        (async () => {
            while (this.recorder_link == null) {
                await sleep(500)
            }
            QRCode.toDataURL(this.recorder_link, {
                //version : 4,
                errorCorrectionLevel: "M",
                type: "image/png",
                margin: 0,
                color: {
                    dark: "#FFFFFF",
                    light: "#FFFFFF00",
                },
            }).then((urlImg) => {
                this.QRcodeSrc = urlImg;
            }).catch(e => {
                console.log(e)
            })
        })()

    },

    methods: {
        ...mapActions(['complete_onboarding_task']),
        copyLinktoClipBoard(evt) {
            this.complete_onboarding_task('share_record_link');
            try {
                evt.stopPropagation();
                navigator.clipboard.writeText(this.recorder_link).then(() => {
                    this.Toaster.info(this.$t("CreativeSpace.info.linkCopied"));
                });
            } catch (e) {
                console.error(e)
            }
        },
        openRecorder() {
            window.location.href = this.recorder_link
        }
    }
}

</script>

<style src="./style.scss" lang="scss" scoped></style>